"use strict";

nadaviOffers = {
  id: 0,
  productName: '',
  cachedRoot: {},
  block: $('#shops-block'),
  block2: $('#shops-block2'),
  message: $(".offers-message"),
  minimalBuyLink: $('#load-more-buy-link'),
  minimalBuyLink2: $('#load-more-buy-link2'),
  loader: $('#shop-loader'),
  more: $('#more-shops'),
  moreButton: $('#more-shops').find("a"),
  bunnerBuyBottom: $('#bannerBuyBottom'),
  buyButton: $('#buy_button'),
  clid: '',
  tabsCounter: $('.buy_tabs__counter'),
  overlay: $('.js-m-overlay'),
  select: $('.js-m-select'),
  count: 0,
  countCache: 0,
  totalCache: 0,
  page: 1,
  showNext: 0,
  isAdSown: false,
  isRobot: false,
  isTouch: false,
  isFirstLoad: true,
  filters: false,
  append: false,
  query: url('?'),
  sort: "",
  order: "",
  priceSorter: $('#priceSorter'),
  priceMobileSorter: $('#priceMobileSorter'),
  sortMobile: $('.m-sort__select'),
  sortMobileTitle: $('.m-sort__select').find('.js-m-select-title'),
  filtersData: {},
  city: '',
  provider: '',
  tab: 0,
  styleClass: '',
  filterBlock: $('.detail-duplex-filter'),
  filterMultiBlock: $('#multiTechDataFilter'),
  filterMultiButton: $('#filterSelectButton'),
  filterPlainBlock: $('#plainTechDataFilter'),
  filterData: {},
  filterChilds: [],
  filterChecked: [],
  filtersObj: [],
  needFilter: false,
  needFilterOnLoad: false,
  init: function init(id, productName, count, filters, needFilter, minimalLink, isTouch, isRobot, city, tab, styleClass) {
    var me = this;
    me.id = id;
    me.productName = productName;
    me.count = count;
    me.countCache = count;
    me.isRobot = isRobot;
    me.isTouch = isTouch;
    me.filters = filters;
    me.needFilter = needFilter;
    me.needFilterOnLoad = needFilter;
    me.clid = me.getClid();
    me.city = city;
    me.tab = tab;
    me.styleClass = styleClass;

    if (minimalLink !== '') {
      var cenyLink = minimalLink + '/kupit' + (city ? '?city=' + city : '');
      this.minimalBuyLink.attr("href", cenyLink);

      if (this.minimalBuyLink2.length > 0) {
        this.minimalBuyLink2.attr("href", cenyLink);
      }
    }

    if (!pt.cookie.get('regionName') || pt.cookie.get('regionName') == null || pt.cookie.get('regionName') == '') {
      citySelect.setText(city);
    }

    if (this.query !== undefined && filters === true) {
      me.initFiltersQueryData();
    }

    me.buttonsInit();
    me.initContextMenu();
    var activeConfig = me.filterPlainBlock.find('input[data-filter-value="' + me.id + '"]');

    if (activeConfig != null && activeConfig.length > 0) {
      me.needFilter = !me.isFilterBlockFilled();
      var value = $(activeConfig).attr('data-filter-value'),
          title = $(activeConfig).next('label').text(),
          value_label = 'value-' + $(activeConfig).attr('id');
      $('.detail-duplex-filter-label .valuegb').remove();
      $(activeConfig).closest('.detail-duplex-filter-dropdown').prev('.detail-duplex-filter-label').append('<span class="value valuegb ' + value_label + '">' + title + '</span>');
      $(activeConfig).prop('checked', true);
      $(activeConfig).addClass('active');
      me.filterChilds = [value] || [];
      $(activeConfig).next('label').css('font-weight', 'bold');
    }

    me.filterPlainBlock.find('input[type=radio]').each(function (index) {
      me.needFilter = !me.isFilterBlockFilled();
      var id = parseInt($(this).attr('data-filter-value')),
          value = $(this).val(),
          name = $(this).attr('data-filter-name');
      var item = {
        id: id,
        value: value,
        name: name
      };
      me.filtersObj.push(item);
    });
    var activeMultiConfig = me.filterMultiBlock.find('input[value*="' + me.id + '"]');

    if (activeMultiConfig != null && activeMultiConfig.length > 0) {
      var filtersRadio = me.filterMultiBlock.find('input[type=radio]'),
          fcount = parseInt(me.filterMultiBlock.attr('data-count')),
          tempArray = [],
          nameArray = [];
      $(filtersRadio).each(function (indx, element) {
        $(element).parent().prev('.detail-filter-label2').removeClass('active');
        $(element).parent().prev('.detail-filter-label2').find('span').remove();
      });

      for (var i = 0; i < activeMultiConfig.length; i++) {
        var arrayRadio = $(activeMultiConfig[i]).val().split(',').map(function (x) {
          return parseInt(x);
        }),
            filterText = $(activeMultiConfig[i]).attr("data-value");
        nameArray.push(filterText);

        for (var j = 0; j < arrayRadio.length; j++) {
          tempArray.push(arrayRadio[j]);
        }

        if (i !== 0) {
          tempArray = tempArray.reduce(function (acc, el, i, arr) {
            if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el);
            return acc;
          }, []);
        }

        $(activeMultiConfig[i]).prop('checked', true);
        $(activeMultiConfig[i]).addClass('checked');
        $(activeMultiConfig[i]).parent().prev('.detail-filter-label2').addClass('active');
        $(activeMultiConfig[i]).parent().prev('.detail-filter-label2').append('<span class="value">' + filterText + '</span>');
      }

      me.filterChilds = tempArray;

      for (var i = 0; i < filtersRadio.length; i++) {
        var valueRadio = $(filtersRadio[i]).val(),
            filtersName = $(filtersRadio[i]).attr("name"),
            arrayRadio = $(filtersRadio[i]).val().split(',').map(function (x) {
          return parseInt(x);
        }),
            isChange = true;

        if (me.filterChilds.length === 0 && name === filtersName) {
          isChange = false;
        }

        if (isChange) {
          if (valueRadio !== "0") {
            $(filtersRadio[i]).attr('disabled', true);
          }

          for (var j = 0; j < me.filterChilds.length; j++) {
            if (arrayRadio.indexOf(me.filterChilds[j]) !== -1) {
              $(filtersRadio[i]).attr('disabled', false);
            }
          }
        }
      }

      if (nameArray.length > 0) {
        me.filterMultiButton.text(nameArray.join(', '));
      } else {
        me.filterMultiButton.text('Выберите параметры');
      }
    }

    me.filterMultiBlock.find('input[type=radio]').each(function (index) {
      var values = $(this).val().split(',').map(function (x) {
        return parseInt(x);
      }),
          value = $(this).attr('data-value'),
          name = $(this).parent().prev('.detail-filter-label2').attr('data-name');

      for (var i = 0; i < values.length; i++) {
        var id = values[i];
        var item = {
          id: id,
          value: value,
          name: name
        };
        me.filtersObj.push(item);
      }
    });
    me.filterPlainBlock.find('input[type=radio]').on(me.isTouch ? 'change' : 'click', function () {
      me.needFilter = !me.isFilterBlockFilled();
      var value = $(this).attr('data-filter-value'),
          title = $(this).next('label').text(),
          value_label = 'value-' + $(this).attr('id'),
          link = $(this).attr('data-filter-link'),
          parentlink = $(this).attr('data-filter-parentlink'),
          redirect = '';
      $('.detail-duplex-filter-label .valuegb').remove();

      if ($(this).hasClass("active")) {
        $(this).prop('checked', null);
        $(this).removeClass('active');
        me.filterChilds = [];
        $(this).next('label').css('font-weight', 'normal');
        redirect = parentlink;
      } else {
        $(this).closest('.detail-duplex-filter-dropdown').prev('.detail-duplex-filter-label').append('<span class="value valuegb ' + value_label + '">' + title + '</span>');
        me.filterPlainBlock.find('input[type=radio].active').removeClass('active').next('label').css('font-weight', 'normal');
        $(this).prop('checked', true);
        $(this).addClass('active');
        me.filterChilds = [value] || [];
        $(this).next('label').css('font-weight', 'bold');
        redirect = link;
      } // Багфикс пагинации. Todo: me.reset();


      me.showNext = 0;
      me.page = 1;
      me.block.empty();

      if (me.filters) {
        me.generateFiltersData();
      }

      var query = url('query');
      var path = url('path');

      if (path !== parentlink) {
        me.message.html('').hide();
        me.more.hide();
        me.loader.show();
        window.location.href = redirect + '?' + query;
      } else {
        me.loadShops();
      }

      $(this).parent().parent().removeClass('expand');
    });
    me.filterMultiBlock.find('input[type=radio]').change(function () {
      var value = $(this).val().split(',').map(function (x) {
        return parseInt(x);
      }),
          name = $(this).attr("name"),
          filtersRadio = me.filterMultiBlock.find('input[type=radio]'),
          checkedRadio = me.filterMultiBlock.find('input.filters-data[type=radio]:checked'),
          fcount = parseInt(me.filterMultiBlock.attr('data-count')),
          parentlink = $(this).attr('data-parentlink'),
          tempArray = [],
          nameArray = [];
      $(filtersRadio).each(function (indx, element) {
        $(element).parent().prev('.detail-filter-label2').removeClass('active');
        $(element).parent().prev('.detail-filter-label2').find('span').remove();
      });
      me.filterChecked = [];

      for (var i = 0; i < checkedRadio.length; i++) {
        var arrayRadio = $(checkedRadio[i]).val().split(',').map(function (x) {
          return parseInt(x);
        }),
            filterText = $(checkedRadio[i]).attr("data-value");
        nameArray.push(filterText);

        for (var j = 0; j < arrayRadio.length; j++) {
          tempArray.push(arrayRadio[j]);
        }

        if (i !== 0) {
          tempArray = tempArray.reduce(function (acc, el, i, arr) {
            if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el);
            return acc;
          }, []);
        }

        $(checkedRadio[i]).addClass('checked');
        $(checkedRadio[i]).parent().prev('.detail-filter-label2').addClass('active');
        $(checkedRadio[i]).parent().prev('.detail-filter-label2').append('<span class="value">' + filterText + '</span>');
        me.filterChecked.push($(checkedRadio[i]).attr("id"));
      }

      me.filterChilds = tempArray;

      for (var i = 0; i < filtersRadio.length; i++) {
        var valueRadio = $(filtersRadio[i]).val(),
            filtersName = $(filtersRadio[i]).attr("name"),
            arrayRadio = $(filtersRadio[i]).val().split(',').map(function (x) {
          return parseInt(x);
        }),
            isChange = true;

        if (me.filterChilds.length === 0 && name === filtersName) {
          isChange = false;
        }

        if (isChange) {
          if (valueRadio !== "0") {
            $(filtersRadio[i]).attr('disabled', true);
          }

          if (checkedRadio.length === 1 && checkedRadio.attr('name') === filtersName || checkedRadio.length === 0) {
            $(filtersRadio[i]).attr('disabled', false);
          }

          for (var j = 0; j < me.filterChilds.length; j++) {
            if (arrayRadio.indexOf(me.filterChilds[j]) !== -1) {
              $(filtersRadio[i]).attr('disabled', false);
            }
          }
        }
      }

      if (nameArray.length > 0) {
        me.filterMultiButton.text(nameArray.join(', '));
      } else {
        me.filterMultiButton.text('Выберите параметры');
      }

      if (me.filters) {
        me.generateFiltersData();
      }

      me.showNext = 0;
      me.page = 1;
      me.block.empty();
      var query = url('query');
      var path = url('path');

      if ((me.filterChilds.length > 1 || me.filterChilds.length === 0) && path !== parentlink) {
        me.message.html('').hide();
        me.more.hide();
        me.loader.show();
        window.location.href = parentlink + '?' + query;
      } else {
        me.loadShops();
      }
    });
  },
  reset: function reset() {
    var me = this;
    me.city = null;
    me.showNext = 0;
    me.page = 1;

    if (me.city != null && me.city != '') {
      me.block.empty();

      if (me.filterBlock) {
        me.filterBlock.empty();
      }
    }
  },
  loadShops: function loadShops() {
    var me = this;
    me.message.html('').hide();
    me.more.hide();
    me.loader.show();
    me.buyButtonClear('<span class="cssload-button"><span class="cssload-zenith"></span></span>');
    var region = citySelect.getCity();
    me.city = region.Name;
    me.showNext = 0;
    me.page = 1;
    me.block.empty();

    if (!me.needFilterOnLoad) {
      me.needFilter = me.needFilterOnLoad;
    }

    $.ajax({
      method: "POST",
      url: '/product/getnadavioffers',
      data: {
        id: me.id,
        city: me.city,
        geoBaseId: region.GeoBaseId,
        mobiGuruId: region.MobiGuruId,
        tab: me.tab,
        filterType: me.filterData.type,
        filterValue: me.filterData.value,
        needFilter: me.needFilter,
        childs: me.filterChilds.join(','),
        filters: JSON.stringify(me.filtersObj)
      },
      dataType: 'html',
      success: function success(data) {
        var result = $(data).filter('#offers-response-data'),
            items = $(data).filter(me.styleClass),
            filter = $(data).filter('#offersMetaData'),
            filterLoaded = me.filterBlock && !me.isFilterBlockFilled();
        me.provider = $(result).attr('data-provider');
        me.cachedRoot["offersCount"] = parseInt($(result).attr("data-offers-count"));
        me.cachedRoot["modelId"] = $(result).attr("data-model-id");
        me.cachedRoot["firstUrl"] = $(result).attr("data-first-url");
        me.cachedRoot["firstPrice"] = $(result).attr("data-first-price");
        me.cachedRoot["firstShop"] = $(result).attr("data-first-shop");
        me.totalCache = me.cachedRoot["offersCount"];

        if (me.cachedRoot["offersCount"] > 0) {
          if (me.isRobot && me.isFirstLoad) {
            me.buyButtonFill(me.cachedRoot["firstUrl"], me.cachedRoot["firstPrice"], 0, me.cachedRoot["modelId"], me.cachedRoot["firstShop"], me.cachedRoot["firstShop"]);
            me.isFirstLoad = false;
          } else {
            me.block.html(items);

            if (me.block2.length > 0) {
              me.block2.html($(items).clone());
            }

            if (me.filterBlock && me.isFilterBlockFilled()) {
              if (me.filterBlock.find('.detail-duplex-filter-color input[type=radio].active').length > 0) {
                me.count = me.block.find(me.styleClass).filter(function (idx, el) {
                  if (me.provider != 'Nadavi' || !me.filterData.type) return true;

                  if (me.filterData.type == 'Color') {
                    return $(el).attr('data-color') == me.filterData.value;
                  }
                }).length;
                me.cachedRoot["offersCount"] = me.count;
                if (me.count > me.countCache) me.count = me.countCache;
              }
            }

            if (filterLoaded) {
              me.filterBlock.append(filter);

              if (me.filterData.type && me.filterData.value) {
                var activeFilter = me.filterBlock.find('.detail-duplex-filter-color input[value="' + me.filterData.value + '"]');

                if ($(activeFilter).length > 0) {
                  var title = $(activeFilter).next('label').text(),
                      value_label = 'value-' + $(activeFilter).attr('id');
                  $(activeFilter).closest('.detail-duplex-filter-dropdown').prev('.detail-duplex-filter-label').append('<span class="value valuecolor ' + value_label + '">' + title + '</span>');
                  $(activeFilter).prop('checked', true);
                  $(activeFilter).addClass('active');
                  me.count = me.block.find(me.styleClass).filter(function (idx, el) {
                    if (me.provider != 'Nadavi' || !me.filterData.type) return true;

                    if (me.filterData.type == 'Color') {
                      return $(el).attr('data-color') == me.filterData.value;
                    }
                  }).length;
                  me.cachedRoot["offersCount"] = me.count;
                  if (me.count > me.countCache) me.count = me.countCache;
                }
              }

              me.filterBlock.find('.detail-duplex-filter-color').click(function (event) {
                if ($(event.target).closest('.detail-duplex-filter-dropdown').length > 0) return true;
                $(this).toggleClass('expand').parent().siblings('.expand').removeClass('expand');
                return false;
              });
              me.filterBlock.find('.detail-duplex-filter-color input[type=radio]').on(me.isTouch ? 'change' : 'click', function (e) {
                var title = $(this).next('label').text(),
                    value_label = 'value-' + $(this).attr('id');
                $('.detail-duplex-filter-label .valuecolor').remove();

                if ($(this).hasClass("active")) {
                  me.filterData.type = null;
                  me.filterData.value = null;
                  $(this).prop('checked', null);
                  $(this).removeClass('active');
                  me.count = me.countCache;
                  me.cachedRoot["offersCount"] = me.totalCache;
                } else {
                  me.filterBlock.find('.detail-duplex-filter-color input[type=radio].active').removeClass('active');
                  $(this).closest('.detail-duplex-filter-dropdown').prev('.detail-duplex-filter-label').append('<span class="value valuecolor ' + value_label + '">' + title + '</span>');
                  me.filterData.type = $(this).attr('data-filter-type');
                  me.filterData.value = $(this).val();
                  $(this).prop('checked', true);
                  $(this).addClass('active');
                  me.count = me.block.find(me.styleClass).filter(function (idx, el) {
                    if (me.provider != 'Nadavi' || !me.filterData.type) return true;

                    if (me.filterData.type == 'Color') {
                      return $(el).attr('data-color') == me.filterData.value;
                    }
                  }).length;
                  me.cachedRoot["offersCount"] = me.count;
                  if (me.count > me.countCache) me.count = me.countCache;
                }

                if (me.provider == 'Nadavi') {
                  me.inputFilters();
                } else {
                  me.loadShops();
                }

                $(this).parent().parent().removeClass('expand');
              });
            }

            me.loadPage();
          }

          if (me.cachedRoot["offersCount"] > 4) {
            me.bunnerBuyBottom.show();
          }

          if (me.cachedRoot["offersCount"] < 5) {
            me.bunnerBuyBottom.hide();
          }
        } else if (data.Error) {
          me.showMessage(true, false);
        } else {
          me.showMessage(false, false);
        }
      },
      error: function error() {
        me.showMessage(true, false);
      }
    });
  },
  loadPage: function loadPage() {
    var me = this;
    me.message.html('').hide();

    if (me.sort === 'PRICE') {
      me.block.find(me.styleClass).tinysort({
        attr: 'data-price',
        order: me.order === 'ASC' ? 'asc' : 'desc'
      });

      if (me.block2.length > 0) {
        me.block2.find(me.styleClass).tinysort({
          attr: 'data-price',
          order: me.order === 'ASC' ? 'asc' : 'desc'
        });
      }
    }

    me.tabsCounter.text('(' + me.cachedRoot["offersCount"] + ')').filter().show();
    me.showNext = me.showNext + me.count <= me.cachedRoot["offersCount"] ? me.showNext + me.count : me.cachedRoot["offersCount"];
    me.loader.hide();
    me.block.find(me.styleClass).filter(function (idx, el) {
      if (me.provider != 'Nadavi' || !me.filterData.type) return true;

      if (me.filterData.type == 'Color') {
        return $(el).attr('data-color') == me.filterData.value;
      }
    }).filter(':lt(' + me.showNext + ')').show();

    if (me.block2.length > 0) {
      me.block2.find(me.styleClass + ':lt(' + me.showNext + ')').show();
    }

    if (me.page === 1) {
      me.loader.hide();
      me.buyButtonFill(me.cachedRoot["firstUrl"], me.cachedRoot["firstPrice"], 0, me.cachedRoot["modelId"], '', me.cachedRoot["firstShop"]);
    } else {
      me.loader.hide();
    }

    if (me.count === 10 && me.cachedRoot["offersCount"] > 10 && me.cachedRoot["offersCount"] !== me.showNext) {
      me.more.show();
    }

    if (me.count === 0) {
      me.showMessage(false, true);
    }
  },
  buyButtonFill: function buyButtonFill(url, price, shopId, modelId, shopUrl, shopName, currency, currencyCode) {
    var me = this,
        cur = '<i class="icon-rouble"></i>';

    if (currencyCode === 'UAH') {
      cur = currency;
    }

    me.buyButton.attr('data-buy-url', url);
    me.buyButton.attr('rel', 'nofollow');
    me.buyButton.attr('data-shop-id', shopId);
    me.buyButton.attr('data-model-id', modelId);
    me.buyButton.attr('data-shop-url', shopUrl);
    me.buyButton.attr('data-shop-name', shopName);
    me.buyButton.addClass('target-blank');
    me.buyButton.removeClass('cursor-default');
    me.buyButton.html('Купить за ' + me.formatFunction(price) + ' ' + cur);
  },
  buyButtonClear: function buyButtonClear(txt) {
    var me = this;
    me.buyButton.removeClass('target-blank');
    me.buyButton.addClass('cursor-default');
    me.buyButton.removeAttr("target");
    me.buyButton.removeAttr("rel");
    me.buyButton.removeAttr('data-shop-id');
    me.buyButton.removeAttr('data-model-id');
    me.buyButton.removeAttr('data-shop-url');
    me.buyButton.removeAttr('data-shop-name');
    me.buyButton.attr('href', '#');
    me.buyButton.html(txt);
  },
  formatFunction: function formatFunction(value) {
    var n = value,
        decPlaces = 0,
        decSeparator = "",
        thouSeparator = " ",
        sign = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    g = Math.abs(n - i).toFixed(decPlaces).slice(2);
    return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (g !== "00" ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
  },
  buttonsInit: function buttonsInit() {
    var me = this;
    me.moreButton.on(me.isTouch ? 'tap' : 'click', function (e) {
      e.preventDefault();

      if (me.isRobot && me.isFirstLoad) {
        me.page += 3;
      } else {
        me.page += 1;
      }

      me.more.hide();
      me.loader.show();
      me.loadPage();
      return false;
    });
    me.select.each(function () {
      var that = $(this),
          head = that.find('.js-m-select-head'),
          title = that.find('.js-m-select-title'),
          option = that.find('.js-m-select-option');
      head.on(me.isTouch ? 'tap' : 'click', function (e) {
        e.preventDefault();
        that.addClass('open');
        me.overlay.show();
      });
      option.on(me.isTouch ? 'tap' : 'click', function () {
        var value = $(this).html();
        that.removeClass('open');
        option.removeClass('active');
        $(this).addClass('active');
        title.html(value);
        me.overlay.hide();
      });
    });
    $(document).on('tap middleclick', '.metrik-target', function () {
      if ($(this).hasClass('cursor-default')) {
        return false;
      }

      var goalUrl = location.href,
          now = +new Date(),
          shopId = $(this).attr('data-shop-id'),
          modelId = $(this).attr('data-model-id'),
          shopUrl = $(this).attr('data-shop-url'),
          shopName = $(this).attr('data-shop-name'),
          shopBuyUrl = $(this).attr('data-buy-url');
      if ($(this).data('lastClick') && now - $(this).data('lastClick') < 500) return false; // Дубликат клика, например IE

      $(this).data('lastClick', now);
      me.loggingClick(goalUrl, modelId, shopId, shopUrl, shopName, shopBuyUrl);
      return true;
    });
    me.priceSorter.on(me.isTouch ? 'tap' : 'click', function (e) {
      e.preventDefault();
      me.priceSorter.siblings().removeClass("active");
      me.sortMobile.find('.active').removeClass("active");
      me.priceSorter.addClass("active");
      me.priceMobileSorter.addClass("active");
      me.sortMobileTitle.html(me.priceMobileSorter.html());
      me.sort = "PRICE";

      if (me.priceSorter.data("order") === "asc") {
        me.order = "ASC";
        me.priceSorter.data("order", "desc").addClass("active-up");
      } else {
        me.order = "DESC";
        me.priceSorter.data("order", "asc").removeClass("active-up");
      }

      me.inputFilters();
    });
    me.priceMobileSorter.on(me.isTouch ? 'tap' : 'click', function (e) {
      e.preventDefault();

      if (me.priceMobileSorter.data("order") === "asc") {
        me.order = "ASC";
        me.priceMobileSorter.data("order", "desc").addClass("active-up");
      } else {
        me.order = "DESC";
        me.priceMobileSorter.data("order", "asc").removeClass("active-up");
      }

      me.sortMobile.find('.active').removeClass("active");
      me.priceMobileSorter.addClass("active");
      me.sortMobileTitle.html(me.priceMobileSorter.html());
      me.sort = "PRICE";
      me.inputFilters();
    });
  },
  generateQuickGuid: function generateQuickGuid() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  },
  loggingClick: function loggingClick(goalUrl, modelId, shopId, shopUrl, shopName, buyUrl) {
    var me = this,
        guid = me.generateQuickGuid();
    var yaMetric = window.yaCounter23506804
    /*Product-test*/
    || window.yaCounter46271199
    /*Rsm*/
    || window.yaCounter47153493
    /*Vse-magazini.ru*/
    || window.yaCounter47165784
    /*pochom.ru*/
    ;

    if (typeof yaMetric !== 'undefined') {
      yaMetric.reachGoal('Offers');
    }

    if (typeof window.ga !== 'undefined') {
      window.ga('send', 'event', 'outbound', 'Offers', goalUrl, {
        'hitCallback': function hitCallback() {
          console.log('Offers');
        }
      });
      window.ga('ecommerce:addTransaction', {
        'id': guid
      });
      window.ga('ecommerce:addItem', {
        'id': guid,
        'name': me.productName
      });
      window.ga('ecommerce:send');
      console.log(guid);
    }

    var url = '/product/offerclick?' + 'modelId=' + (modelId || '') + '&shopId=' + (shopId || '') + '&productId=' + (me.id || '') + '&shopUrl=' + (shopUrl || '') + '&shopName=' + (shopName || '') + '&region=' + (me.city || '') + '&clid=' + (me.clid || '') + '&provider=' + (me.provider || '') + '&buyurl=' + encodeURIComponent(buyUrl);
    window.open(url);
  },
  initContextMenu: function initContextMenu() {
    var me = this;
    $.contextMenu({
      selector: '.metrik-target',
      build: function build($trigger, e) {
        var target = $($trigger[0]),
            url = target.attr("href"),
            goalUrl = location.href,
            shopId = target.attr('data-shop-id'),
            modelId = target.attr('data-model-id'),
            shopUrl = target.attr('data-shop-url'),
            shopName = $(this).attr('data-shop-name'),
            shopBuyUrl = $(this).attr('data-buy-url');

        $.contextMenu.types.opentab = function (item, opt, root) {
          $('<span>Открыть ссылку в новой вкладке</span>').appendTo(this).on('click', function () {
            me.loggingClick(goalUrl, modelId, shopId, shopUrl, shopName, shopBuyUrl);
            $trigger[0].click();
            root.$menu.trigger('contextmenu:hide');
          });
        };

        if (!me.isTouch) {
          $.contextMenu.types.openwindow = function (item, opt, root) {
            $('<span>Открыть ссылку в новом окне</span>').appendTo(this).on('click', function () {
              me.loggingClick(goalUrl, modelId, shopId, shopUrl, shopName, shopBuyUrl);
              window.open(url, '_blank', 'toolbar=yes,menubar=yes,location=yes,scrollbars=yes,resizable=yes,status=yes');
              root.$menu.trigger('contextmenu:hide');
            });
          };
        }

        $.contextMenu.types.copyurl = function (item, opt, root) {
          $('<span>Копировать ссылку</span>').appendTo(this).addClass('copyurl').attr('data-clipboard-text', url);
          var clipboard = new Clipboard('.copyurl');
          clipboard.on('success', function (e) {
            me.loggingClick(goalUrl, modelId, shopId, shopUrl, shopName, shopBuyUrl);
            e.clearSelection();
            clipboard.destroy();
            root.$menu.trigger('contextmenu:hide');
          });
        };

        $.contextMenu.types.areaurl = function (item, opt, root) {
          $('<span>' + url + '</span>').appendTo(this).addClass('context-menu-link');
        };

        if (!me.isTouch) {
          return {
            items: {
              opentab: {
                type: "opentab",
                customName: "OpenInNewTab"
              },
              openwindow: {
                type: "openwindow",
                customName: "OpenInNewWindow"
              },
              "sep1": "---------",
              copyurl: {
                type: "copyurl",
                customName: "CopyUrl"
              }
            }
          };
        } else {
          return {
            items: {
              areaurl: {
                type: "areaurl",
                customName: "AreaUrl"
              },
              opentab: {
                type: "opentab",
                customName: "OpenInNewTab"
              },
              "sep1": "---------",
              copyurl: {
                type: "copyurl",
                customName: "CopyUrl"
              }
            }
          };
        }
      }
    });
  },
  initFiltersQueryData: function initFiltersQueryData() {
    var me = this;

    if (me.query.Sort !== undefined && me.query.Sort !== '') {
      me.sort = me.query.Sort;

      if (me.sort === 'PRICE') {
        me.priceSorter.siblings().removeClass("active");
        me.sortMobile.find('.active').removeClass("active");
        me.priceSorter.addClass("active");
        me.priceMobileSorter.addClass("active");
        me.sortMobileTitle.html(me.priceMobileSorter.html());
      }

      if (me.sort === 'RATING') {
        me.shopSorter.siblings().removeClass("active").removeClass("active-up");
        me.sortMobile.find('.active').removeClass("active");
        me.shopSorter.addClass("active");
        me.shopMobileSorter.addClass("active");
        me.sortMobileTitle.html(me.shopMobileSorter.html());
      }
    }

    if (me.query.Order !== undefined && me.query.Order !== '') {
      me.order = me.query.Order;

      if (me.order === "ASC") {
        me.priceSorter.data("order", "desc").addClass("active-up");
      } else {
        me.priceSorter.data("order", "asc").removeClass("active-up");
      }
    }

    if (me.query.filterType !== undefined && me.query.filterType !== '') {
      me.filtersData.filterType = me.query.filterType;
      me.filterData.type = me.query.filterType;
    }

    if (me.query.filterValue !== undefined && me.query.filterValue !== '') {
      me.filtersData.filterValue = me.query.filterValue;
      me.filterData.value = me.query.filterValue;
    }

    if (me.query.Childs !== undefined && me.query.Childs !== '') {
      me.filtersData.Childs = me.query.Childs;
      me.filterChilds = me.query.Childs.split(',');

      if (me.filterPlainBlock && me.filterPlainBlock.length > 0) {
        var childsFilter = me.filterPlainBlock.find('input[data-filter-value="' + me.filtersData.Childs + '"]');

        if ($(childsFilter).length > 0) {
          var value = $(childsFilter).attr('data-filter-value'),
              title = $(childsFilter).next('label').text(),
              value_label = 'value-' + $(childsFilter).attr('id');
          $(childsFilter).closest('.detail-duplex-filter-dropdown').prev('.detail-duplex-filter-label').append('<span class="value valuegb ' + value_label + '">' + title + '</span>');
          $(childsFilter).prop('checked', true);
          $(childsFilter).addClass('active');
          $(childsFilter).next('label').css('font-weight', 'bold');
        }
      }
    }

    if (me.query.Checked !== undefined && me.query.Checked !== '') {
      me.filtersData.Checked = me.query.Checked;
      me.filterChecked = me.query.Checked.split(',');

      if (me.filterMultiBlock && me.filterMultiBlock.length > 0) {
        var filtersRadio = me.filterMultiBlock.find('input[type=radio]'),
            fcount = parseInt(me.filterMultiBlock.attr('data-count')),
            tempArray = [],
            nameArray = [];

        for (var i = 0; i < me.filterChecked.length; i++) {
          var checkedFilter = me.filterMultiBlock.find('input[id="' + me.filterChecked[i] + '"]');
          var arrayRadio = $(checkedFilter).val().split(',').map(function (x) {
            return parseInt(x);
          }),
              filterText = $(checkedFilter).attr("data-value");
          nameArray.push(filterText);

          for (var j = 0; j < arrayRadio.length; j++) {
            tempArray.push(arrayRadio[j]);
          }

          if (i !== 0) {
            tempArray = tempArray.reduce(function (acc, el, i, arr) {
              if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el);
              return acc;
            }, []);
          }

          $(checkedFilter).prop('checked', true);
          $(checkedFilter).addClass('checked');
          $(checkedFilter).parent().prev('.detail-filter-label2').addClass('active');
          $(checkedFilter).parent().prev('.detail-filter-label2').append('<span class="value">' + filterText + '</span>');
        }

        me.filterChilds = tempArray;

        for (var i = 0; i < filtersRadio.length; i++) {
          var valueRadio = $(filtersRadio[i]).val(),
              filtersName = $(filtersRadio[i]).attr("name"),
              arrayRadio = $(filtersRadio[i]).val().split(',').map(function (x) {
            return parseInt(x);
          }),
              isChange = true;

          if (me.filterChilds.length === 0 && name === filtersName) {
            isChange = false;
          }

          if (isChange) {
            if (valueRadio !== "0") {
              $(filtersRadio[i]).attr('disabled', true);
            }

            for (var j = 0; j < me.filterChilds.length; j++) {
              if (arrayRadio.indexOf(me.filterChilds[j]) !== -1) {
                $(filtersRadio[i]).attr('disabled', false);
              }
            }
          }
        }

        if (nameArray.length > 0) {
          me.filterMultiButton.text(nameArray.join(', '));
        } else {
          me.filterMultiButton.text('Выберите параметры');
        }
      }
    }
  },
  inputFilters: function inputFilters() {
    var me = this;
    me.more.hide();
    me.block.find(me.styleClass).hide();
    me.loader.show();
    me.page = 1;
    me.showNext = 0;

    if (me.filters === true) {
      me.generateFiltersData();
    }

    me.loadPage();
  },
  generateFiltersData: function generateFiltersData() {
    var me = this;

    if (me.filters === true) {
      me.append = true;
    }

    delete me.filtersData.Sort;

    if (me.sort !== '') {
      me.filtersData.Sort = me.sort;
    }

    delete me.filtersData.Order;

    if (me.order !== '') {
      me.filtersData.Order = me.order;
    }

    delete me.filtersData.city;

    if (me.city && me.city !== '') {
      me.filtersData.city = me.city;
    }

    delete me.filtersData.filterValue;
    delete me.filtersData.filterType;

    if (me.filterData.type == 'Color') {
      me.filtersData.filterType = me.filterData.type;
      me.filtersData.filterValue = me.filterData.value;
    }

    delete me.filtersData.Childs;

    if (me.filterChilds.length > 0) {
      me.filtersData.Childs = me.filterChilds.join(',');
    }

    delete me.filtersData.Checked;

    if (me.filterChecked.length > 0) {
      me.filtersData.Checked = me.filterChecked.join(',');
    }

    if (me.append === true) {
      var search = Object.keys(me.filtersData).map(function (k) {
        return encodeURIComponent(k) + '=' + me.filtersData[k];
      }).join('&');
      history.pushState('', '', window.location.pathname + '?' + search);
    }
  },
  getClid: function getClid() {
    var metricsMarkers = ['gclid', 'yclid', 'k50id'],
        cookieName = 'clid',
        clid,
        cookieDurationMinutes = 60 * 24;
    $.each(metricsMarkers, function (_, value) {
      var metricMatches = document.location.search.match(new RegExp(value));

      if (metricMatches && metricMatches.length > 0) {
        var endStr = metricMatches.input.substr(metricMatches.index);
        var endIndex = endStr.length;
        var endMatches = endStr.match(new RegExp('&'));

        if (endMatches && endMatches.length > 0) {
          endIndex = endMatches.index;
        }

        clid = endStr.substring(0, endIndex);
      }
    });

    if (!clid) {
      clid = pt.cookie.get(cookieName);
    } else {
      pt.cookie.set(cookieName, clid, {
        path: "/",
        expires: cookieDurationMinutes * 60
      });
    }

    return clid;
  },
  showMessage: function showMessage(isError, isFilter) {
    var me = this,
        errorText = 'Предложения в данный момент недоступны, попробуйте повторить позже.',
        messageText = 'К сожалению, для Вашего региона предложения не найдены.',
        messageFilterText = 'К сожалению, для Вашего региона предложения не найдены.<br/>Попробуйте изменить фильтр.',
        text = '';
    me.tabsCounter.text('(0)').show();
    me.buyButtonClear('нет цен');
    me.loader.hide();

    if (me.message) {
      if (isError) {
        text = errorText;
      } else {
        if (isFilter) {
          text = messageFilterText;
        } else {
          text = messageText;
        }
      }

      me.message.html(text).show();
    }
  },
  isFilterBlockFilled: function isFilterBlockFilled() {
    return $(this.filterBlock).find('.detail-duplex-filter-color').length > 0;
  }
};