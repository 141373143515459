"use strict";

popups = {
  activityIndicatorOn: function activityIndicatorOn() {
    $('<div id="imagelightbox-loading"></div>').appendTo('.zoom-content');
  },
  activityIndicatorOff: function activityIndicatorOff() {
    $('#imagelightbox-loading').remove();
  },
  overlayOn: function overlayOn() {
    $('<div id="imagelightbox-overlay"></div>').appendTo('body');
    $('<div id="imagelightbox-popup">' + '<div class="zoom-dialog">' + '<div class="zoom-content">' + '<div class="zoom-image">' + '</div>' + '<div class="zoom-caption">' + '</div>' + '</div>' + '</div>' + '</div>').appendTo('body');
  },
  overlayOff: function overlayOff() {
    $('#imagelightbox-overlay').remove();
    $('#imagelightbox-popup').remove();
  },
  captionOn: function captionOn() {
    var description = $('img#imagezoom[data-blob="' + $('#imagelightbox').attr('src') + '"]').attr('alt');
    if (description.length > 0) $('<div class="zoom-label">' + description + '</div>').appendTo('.zoom-caption');
  },
  captionOff: function captionOff() {
    $('.zoom-label').remove();
  },
  closeButtonOn: function closeButtonOn(instance) {
    $('<button type="button" id="imagelightbox-close" title="Закрыть"><span class="icon iconClose"></span></button>').appendTo('.zoom-content').on('click touchend', function () {
      $(this).remove();
      instance.quitImageLightbox();
      return false;
    });
  },
  closeButtonOff: function closeButtonOff() {
    $('#imagelightbox-close').remove();
  },
  navigationOn: function navigationOn(instance, selector) {
    var images = $(selector);

    if (images.length) {
      var nav = $('<div id="imagelightbox-nav"></div>');

      for (var i = 0; i < images.length; i++) {
        nav.append('<button type="button"></button>');
      }

      nav.appendTo('.zoom-content');
      nav.on('click touchend', function () {
        return false;
      });
      var navItems = nav.find('button');
      navItems.on('click touchend', function () {
        var $this = $(this);
        if (images.eq($this.index()).attr('data-blob') != $('#imagelightbox').attr('src')) instance.switchImageLightbox($this.index());
        navItems.removeClass('active');
        navItems.eq($this.index()).addClass('active');
        return false;
      }).on('touchend', function () {
        return false;
      });
    }
  },
  navigationUpdate: function navigationUpdate() {
    var items = $('#imagelightbox-nav button');
    items.removeClass('active');
    var index = parseInt($('img#imagezoom[data-blob="' + $('#imagelightbox').attr('src') + '"]').attr('data-index'));
    items.eq(index).addClass('active');
  },
  navigationOff: function navigationOff() {
    $('#imagelightbox-nav').remove();
  },
  arrowsOn: function arrowsOn(instance, selector) {
    var $arrows = $('<button type="button" class="imagelightbox-arrow imagelightbox-arrow-left"><span class="btn__slider btnPrevBig"></span></button><button type="button" class="imagelightbox-arrow imagelightbox-arrow-right"><span class="btn__slider btnNextBig"></span></button>');
    $arrows.appendTo('.zoom-content');
    $arrows.on('click touchend', function (e) {
      e.preventDefault();
      var $this = $(this),
          index = parseInt($('img#imagezoom[data-blob="' + $('#imagelightbox').attr('src') + '"]').attr('data-index'));

      if ($this.hasClass('imagelightbox-arrow-left')) {
        index = index - 1;
        if (!$(selector).eq(index).length) index = $(selector).length;
      } else {
        index = index + 1;
        if (index >= $(selector).length) index = 0;
      }

      instance.switchImageLightbox(index);
      return false;
    });
  },
  arrowsOff: function arrowsOff() {
    $('.imagelightbox-arrow').remove();
  }
};

(function ($, window, document, undefined) {
  'use strict';

  var cssTransitionSupport = function cssTransitionSupport() {
    var s = document.body || document.documentElement,
        s = s.style;
    if (s.WebkitTransition == '') return '-webkit-';
    if (s.MozTransition == '') return '-moz-';
    if (s.OTransition == '') return '-o-';
    if (s.transition == '') return '';
    return false;
  },
      isCssTransitionSupport = cssTransitionSupport() === false ? false : true,
      cssTransitionTranslateX = function cssTransitionTranslateX(element, positionX, speed) {
    var options = {},
        prefix = cssTransitionSupport();
    options[prefix + 'transform'] = 'translateX(' + positionX + ')';
    options[prefix + 'transition'] = prefix + 'transform ' + speed + 's linear';
    element.css(options);
  },
      hasTouch = 'ontouchstart' in window,
      hasPointers = window.navigator.pointerEnabled || window.navigator.msPointerEnabled,
      wasTouched = function wasTouched(event) {
    if (hasTouch) return true;
    if (!hasPointers || typeof event === 'undefined' || typeof event.pointerType === 'undefined') return false;

    if (typeof event.MSPOINTER_TYPE_MOUSE !== 'undefined') {
      if (event.MSPOINTER_TYPE_MOUSE != event.pointerType) return true;
    } else if (event.pointerType != 'mouse') return true;

    return false;
  };

  $.fn.imageLightbox = function (options) {
    var options = $.extend({
      selector: 'id="imagelightbox"',
      animationSpeed: 250,
      preloadNext: false,
      enableKeyboard: true,
      quitOnEnd: false,
      quitOnImgClick: false,
      quitOnDocClick: true,
      onStart: false,
      onEnd: false,
      onLoadStart: false,
      onLoadEnd: false
    }, options),
        targets = $([]),
        target = $(),
        image = $(),
        imageWidth = 0,
        imageHeight = 0,
        swipeDiff = 0,
        inProgress = false,
        setImage = function setImage() {
      if (!image.length) return true;
      var screenWidth = 980,
          screenHeight = 615,
          tmpImage = new Image();
      tmpImage.src = image.attr('src');

      tmpImage.onload = function () {
        imageWidth = tmpImage.width;
        imageHeight = tmpImage.height;

        if (imageWidth > screenWidth || imageHeight > screenHeight) {
          var ratio = imageWidth / imageHeight > screenWidth / screenHeight ? imageWidth / screenWidth : imageHeight / screenHeight;
          imageWidth /= ratio;
          imageHeight /= ratio;
        }

        var parent = $('.zoom-image');
        image.css({
          'top': (parent.height() - imageHeight) / 2 + 'px',
          'left': (parent.width() - imageWidth) / 2 + 'px'
        });
      };
    },
        loadImage = function loadImage(direction) {
      if (inProgress) return false;
      direction = typeof direction === 'undefined' ? false : direction == 'left' ? 1 : -1;

      if (image.length) {
        if (direction !== false && (targets.length < 2 || options.quitOnEnd === true && (direction === -1 && targets.index(target) == 0 || direction === 1 && targets.index(target) == targets.length - 1))) {
          quitLightbox();
          return false;
        }

        var params = {
          'opacity': 0
        };
        if (isCssTransitionSupport) cssTransitionTranslateX(image, 100 * direction - swipeDiff + 'px', options.animationSpeed / 1000);else params.left = parseInt(image.css('left')) + 100 * direction + 'px';
        image.animate(params, options.animationSpeed, function () {
          removeImage();
        });
        swipeDiff = 0;
      }

      inProgress = true;
      if (options.onLoadStart !== false) options.onLoadStart();
      setTimeout(function () {
        image = $('<img ' + options.selector + ' />').attr('src', target.attr('data-blob')).on('load', function () {
          image.appendTo('.zoom-image');
          setImage();
          var params = {
            'opacity': 1
          };
          image.css('opacity', 0);

          if (isCssTransitionSupport) {
            cssTransitionTranslateX(image, -100 * direction + 'px', 0);
            setTimeout(function () {
              cssTransitionTranslateX(image, 0 + 'px', options.animationSpeed / 1000);
            }, 50);
          } else {
            var imagePosLeft = parseInt(image.css('left'));
            params.left = imagePosLeft + 'px';
            image.css('left', imagePosLeft - 100 * direction + 'px');
          }

          image.animate(params, options.animationSpeed, function () {
            inProgress = false;
            if (options.onLoadEnd !== false) options.onLoadEnd();
          });

          if (options.preloadNext) {
            var nextTarget = targets.eq(targets.index(target) + 1);
            if (!nextTarget.length) nextTarget = targets.eq(0);
            $('<img />').attr('src', nextTarget.attr('data-blob'));
          }
        }).on('error', function () {
          if (options.onLoadEnd !== false) options.onLoadEnd();
        });
        var swipeStart = 0,
            swipeEnd = 0,
            imagePosLeft = 0;
        image.on(hasPointers ? 'pointerup MSPointerUp' : 'click', function (e) {
          e.preventDefault();

          if (options.quitOnImgClick) {
            quitLightbox();
            return false;
          }

          if (wasTouched(e.originalEvent)) return true;
          var posX = (e.pageX || e.originalEvent.pageX) - e.target.offsetLeft;
          target = targets.eq(targets.index(target) - (imageWidth / 2 > posX ? 1 : -1));
          if (!target.length) target = targets.eq(imageWidth / 2 > posX ? targets.length : 0);
          loadImage(imageWidth / 2 > posX ? 'left' : 'right');
        }).on('touchstart pointerdown MSPointerDown', function (e) {
          if (!wasTouched(e.originalEvent) || options.quitOnImgClick) return true;
          if (isCssTransitionSupport) imagePosLeft = parseInt(image.css('left'));
          swipeStart = e.originalEvent.pageX || e.originalEvent.touches[0].pageX;
        }).on('touchmove', function (e) {
          if (!wasTouched(e.originalEvent) || options.quitOnImgClick) return true;
          e.preventDefault();
          swipeEnd = e.originalEvent.pageX || e.originalEvent.touches[0].pageX;
          swipeDiff = swipeStart - swipeEnd;
          if (isCssTransitionSupport) cssTransitionTranslateX(image, -swipeDiff + 'px', 0);else image.css('left', imagePosLeft - swipeDiff + 'px');
        }).on('touchend touchcancel pointerup pointercancel MSPointerUp MSPointerCancel', function (e) {
          if (!wasTouched(e.originalEvent) || options.quitOnImgClick) return true;

          if (Math.abs(swipeDiff) > 50) {
            target = targets.eq(targets.index(target) - (swipeDiff < 0 ? 1 : -1));
            if (!target.length) target = targets.eq(swipeDiff < 0 ? targets.length : 0);
            loadImage(swipeDiff > 0 ? 'right' : 'left');
          } else {
            if (isCssTransitionSupport) cssTransitionTranslateX(image, 0 + 'px', options.animationSpeed / 1000);else image.animate({
              'left': imagePosLeft + 'px'
            }, options.animationSpeed / 2);
          }
        });
      }, options.animationSpeed + 100);
    },
        removeImage = function removeImage() {
      if (!image.length) return false;
      image.remove();
      image = $();
    },
        quitLightbox = function quitLightbox() {
      if (!image.length) return false;
      image.animate({
        'opacity': 0
      }, options.animationSpeed, function () {
        removeImage();
        inProgress = false;
        if (options.onEnd !== false) options.onEnd();
      });
    },
        addTargets = function addTargets(newTargets) {
      newTargets.each(function () {
        targets = targets.add($(this));
      });
      newTargets.on('click.imageLightbox', function (e) {
        e.preventDefault();
        if (inProgress) return false;
        inProgress = false;
        if (options.onStart !== false) options.onStart();
        target = $(this);
        loadImage();
      });
    },
        zoomTargets = function zoomTargets() {
      if (inProgress) return false;
      inProgress = false;
      if (options.onStart !== false) options.onStart();
    };

    $(window).on('resize', setImage);

    if (options.quitOnDocClick) {
      $(document).on(hasTouch ? 'touchend' : 'click', function (e) {
        if (image.length && !$(e.target).is(image) && $(e.target).prop('id') === 'imagelightbox-popup') quitLightbox();
      });
    }

    if (options.enableKeyboard) {
      $(document).on('keyup', function (e) {
        if (!image.length) return true;
        e.preventDefault();
        if (e.keyCode == 27) quitLightbox();

        if (e.keyCode == 37 || e.keyCode == 39) {
          target = targets.eq(targets.index(target) - (e.keyCode == 37 ? 1 : -1));
          if (!target.length) target = targets.eq(e.keyCode == 37 ? targets.length : 0);
          loadImage(e.keyCode == 37 ? 'left' : 'right');
        }
      });
    }

    addTargets($(this));

    this.switchImageLightbox = function (index) {
      var tmpTarget = targets.eq(index);

      if (tmpTarget.length) {
        var currentIndex = targets.index(target);
        target = tmpTarget;
        loadImage(index < currentIndex ? 'left' : 'right');
      }

      return this;
    };

    this.zoomImageLightbox = function () {
      zoomTargets();
    };

    this.addToImageLightbox = function (newTargets) {
      addTargets(newTargets);
    };

    this.quitImageLightbox = function () {
      quitLightbox();
      return this;
    };

    return this;
  };
})(jQuery, window, document);