"use strict";

var citySelect = {
  city: $('#city-link'),
  cityTitle: $('.head__title-city'),
  cityList: $('.buy__drop'),
  overlay: $('.js-m-overlay'),
  select: $('.js-m-select'),
  mselect: $('#m-city-select-wrap'),
  mcityTitle: $('.js-m-city-title'),
  mcity: $('.js-m-city'),
  wrapperMobile: $('#m-wrapper'),
  regionName: null,
  geoBaseId: 0,
  mobiGuruId: 0,
  textChangeFunc: null,
  queryChange: false,
  init: function init(regionName, textChangeFunc, queryChange) {
    this.regionName = regionName == null || regionName == "" ? pt.cookie.get('regionName') : regionName;
    this.geoBaseId = pt.cookie.get('geoBaseId') === null || pt.cookie.get('geoBaseId') === "undefined" ? null : pt.cookie.get('geoBaseId');
    this.mobiGuruId = pt.cookie.get('mobiGuruId') === null || pt.cookie.get('mobiGuruId') === "undefined" ? null : pt.cookie.get('mobiGuruId');
    this.textChangeFunc = textChangeFunc;
    this.queryChange = queryChange;
    this.hide();

    if (!this.checkRegion()) {
      this.hide();
      this.getLocation();
    } else {
      this.setText(this.regionName, this.geoBaseId, this.mobiGuruId);
    }

    this.buttonsInit();
    this.mButtonsInit();
  },
  checkRegion: function checkRegion() {
    var me = this;
    if (me.regionName == null || me.regionName == "") return false;
    if (me.geoBaseId === null || me.geoBaseId === 0) return false;
    if (pt.cookie.get('regionName') !== me.regionName) return false;
    return true;
  },
  mButtonsInit: function mButtonsInit() {
    var me = this;
    me.mselect.on(me.isTouch ? 'tap' : 'click', function (e) {
      e.preventDefault();
      me.mcity.find('a.active').removeClass('active');
      var active = me.mcity.find("a[data-geobase-id='" + me.geoBaseId + "']");
      $(document).scrollTop(0);
      active.addClass('active');
      me.wrapperMobile.addClass('hide');
      me.mcity.addClass('show');
    });

    var onOptionClick = function onOptionClick(e) {
      e.preventDefault();
      $(this).parent().find('a.active').removeClass('active');
      $(this).addClass('active');
      me.setText($(this).text(), $(this).attr('data-geobase-id'), $(this).attr('data-mobiguru-id'));
      me.mcity.removeClass('show');
      me.wrapperMobile.removeClass('hide');
    };

    var cityAjax = $('#city_list_ajax');
    var cityList = $('#city_list_template');
    var input = $('.m-choice__input');

    var mCityListClean = function mCityListClean() {
      if (cityList.hasClass('m-hide')) {
        input.val('');
        cityAjax.addClass('m-hide');
        cityList.removeClass('m-hide');
        cityAjax.empty();
      }
    };

    $('.js-m-city-option').on(me.isTouch ? 'tap' : 'click', onOptionClick);
    input.on('propertychange input', function (e) {
      var value = input.val();

      if (value == "") {
        mCityListClean();
      }

      if (value.length > 2) {
        cityAjax.empty();
        $.ajax({
          url: '/product/searchregion',
          dataType: 'json',
          data: {
            query: value
          },
          success: function success(data) {
            var cityTmpl = $.templates("#cityTmpl");

            if (data.result && data.result.length) {
              $(cityTmpl.render(data.result)).appendTo(cityAjax);
            } else {
              $(cityTmpl.render(data)).appendTo(cityAjax);
            }

            cityList.addClass('m-hide');
            cityAjax.removeClass('m-hide');
            $('.js-m-city-ajax-option').on(me.isTouch ? 'tap' : 'click', onOptionClick);
          }
        });
      }
    });
    $('.m-block-back').on(me.isTouch ? 'tap' : 'click', mCityListClean);
  },
  buttonsInit: function buttonsInit() {
    var me = this;

    var onCityClick = function onCityClick(e) {
      e.preventDefault();
      var active = $("a.buy__cities-link[data-geobase-id='" + me.geoBaseId + "']");

      if (!me.cityList.is(':visible')) {
        $(".js-buy").addClass('expanded');
        active.addClass('active');
        me.overlay.show();
        me.cityList.show();
      } else {
        me.cityList.hide();
        me.overlay.hide();
        active.removeClass('active');
        $(".js-buy").removeClass('expanded');
      }

      return false;
    };

    me.city.bind(me.isTouch ? 'tap' : 'click', onCityClick);
    me.overlay.on(me.isTouch ? 'tap' : 'click', function (e) {
      e.preventDefault();

      if ($('.header').hasClass('upper')) {
        $('.header__search-input').blur();
        $('.header').removeClass('upper');
      }

      me.select.removeClass('open');

      if (me.cityList.length > 0 && me.cityList.is(':visible')) {
        me.cityList.find('a.active').removeClass('active');
        $('.buy__search-input').autocomplete('hide');
        me.cityList.hide();
        me.overlay.hide();
        $(".js-buy").removeClass('expanded');
        $(".buy__search-input").val("");
        $('.buy__search-input').autocomplete('clear');
      }

      me.overlay.hide();
    });
    $('.buy__drop a').on(me.isTouch ? 'tap' : 'click', function (e) {
      e.preventDefault();
      me.cityList.find('a.active').removeClass('active');
      $(this).addClass('active');
      me.setText($(this).text(), $(this).attr('data-geobase-id'), $(this).attr('data-mobiguru-id'));
      me.cityList.hide();
      $(".autocomplete-suggestions").hide();
      me.overlay.hide();
      $(".js-buy").removeClass('expanded');
      me.isSort = true;
    });
    $('.buy__search-input').autocomplete({
      serviceUrl: '/product/searchregion',
      dataType: 'json',
      minChars: 2,
      transformResult: function transformResult(response, originalQuery) {
        if (response.result && response.result.length) {
          var json = response.result;
          return {
            suggestions: $.map(json, function (dataItem) {
              return {
                value: dataItem.name,
                geobaseid: dataItem.geobaseid,
                mobiguruid: dataItem.mobiguruid
              };
            })
          };
        }

        return null;
      },
      onSelect: function onSelect(suggestion) {
        me.setText(suggestion.value, suggestion.geobaseid, suggestion.mobiguruid);
        me.cityList.find('a.active').removeClass('active');
        me.cityList.hide();
        me.overlay.hide();
        $(".js-buy").removeClass('expanded');
        $(".buy__search-input").val("");
      }
    });
  },
  getLocation: function getLocation() {
    var me = this,
        city;

    if (!me.checkRegion()) {
      city = me.regionName;
    }

    $.ajax({
      url: '/product/getlocation',
      data: {
        city: city
      },
      success: function success(data) {
        if (data && data.Name) {
          me.setText(data.Name, data.GeoBaseId, data.MobiGuruId);
          return data;
        } else {
          me.setText('Москва', 2097, 213);
        }
      }
    });
    return {
      'Name': 'Москва',
      'GeoBaseId': 2097,
      'MobiGuruId': 213
    };
  },
  getCity: function getCity() {
    var regionName = this.regionName == null || this.regionName == "" ? pt.cookie.get('regionName') : this.regionName;
    var geoBaseId = this.geoBaseId == null || this.geoBaseId == "" ? pt.cookie.get('geoBaseId') : this.geoBaseId;
    var mobiGuruId = this.mobiGuruId == null || this.mobiGuruId == "" ? pt.cookie.get('mobiGuruId') : this.mobiGuruId;
    return {
      'Name': regionName,
      'GeoBaseId': geoBaseId,
      'MobiGuruId': mobiGuruId
    };
  },
  setText: function setText(text, geoBaseId, mobiGuruId) {
    this.regionName = text;
    this.geoBaseId = geoBaseId;
    this.mobiGuruId = mobiGuruId;
    this.setCookie();
    if (text && text.length == 0) this.hide();else this.show();
    if (this.textChangeFunc != null) this.textChangeFunc(text);
    if (this.queryChange) this.setQuery();

    if (this.city) {
      this.city.text('г. ' + text);
      this.city.attr('data-geobase-id', geoBaseId);
      this.city.attr('data-mobiguru-id', mobiGuruId);
    }

    if (this.mcityTitle) {
      this.mcityTitle.text('г. ' + text);
      this.cityTitle.text('г. ' + text);
      this.mcityTitle.attr('data-geobase-id', geoBaseId);
      this.mcityTitle.attr('data-mobiguru-id', mobiGuruId);
    }
  },
  hide: function hide() {
    if (this.city) this.city.parent().hide();
    if (this.mselect) this.mselect.addClass("hidden");
  },
  show: function show() {
    if (this.city) this.city.parent().show();
    if (this.mselect) this.mselect.removeClass("hidden");
  },
  setCookie: function setCookie() {
    pt.cookie.set('geoBaseId', this.geoBaseId, {
      path: "/",
      expires: 100000000
    });
    pt.cookie.set('mobiGuruId', this.mobiGuruId, {
      path: "/",
      expires: 100000000
    });
    pt.cookie.set('regionName', this.regionName, {
      path: "/",
      expires: 100000000
    });
  },
  setQuery: function setQuery() {
    var match,
        pl = /\+/g,
        // Regex for replacing addition symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
        decode = function decode(s) {
      return decodeURIComponent(s.replace(pl, " "));
    },
        query = window.location.search.substring(1);

    var urlParams = {};

    while (match = search.exec(query)) {
      urlParams[decode(match[1])] = decode(match[2]);
    }

    urlParams['city'] = this.regionName;
    var search = Object.keys(urlParams).map(function (k) {
      return encodeURIComponent(k) + '=' + urlParams[k];
    }).join('&');
    history.pushState('', '', window.location.pathname + '?' + search);
  }
};