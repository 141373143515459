"use strict";

// -------------------------------------
// Compare
// -------------------------------------
var compare = {
  init: function init(settings) {
    compare.config = {
      block: $('.blockCompare'),
      speed: 200
    };
    $.extend(compare.config, settings);
    compare.setup();
  },
  setup: function setup() {
    compare.$compare = compare.config.block;
    compare.$head = $('.blockCompare-head', compare.$compare);
    compare.$body = $('.blockCompare-body', compare.$compare);
    compare.$speed = compare.config.speed;
    compare.$scrollPane = $('.blockCompare-scroll', compare.$head);
    setTimeout(function () {
      // Scroll pane
      var $scrollPane = compare.$scrollPane.on('jsp-scroll-x', function (event, scrollPositionX) {
        $('.blockCompare-scroll', compare.$body).scrollLeft(scrollPositionX);
      }).jScrollPane({
        verticalDragMinHeight: 80,
        verticalDragMaxHeight: 80,
        horizontalDragMinWidth: 80,
        horizontalDragMaxWidth: 80
      });
      compare.$scrollPaneAPI = $scrollPane.data('jsp');
      compare.headPosition();
      compare.$headPosition = compare.$head.offset();
      compare.resize(); // On windows scroll

      $(window).on('scroll.compare', function () {
        var $scrollTop = $(window).scrollTop(),
            $scrollLeft = $(window).scrollLeft();
        compare.onPageScroll($scrollTop);
      }).trigger('scroll.compare');
    }, compare.$speed);
  },
  resize: function resize() {
    var $headPosition = compare.$headPosition,
        $headH,
        $tProducts = $('.blockCompare-tableProducts', compare.$compare),
        $tProperties = $('.blockCompare-tableProperties', compare.$compare),
        $tValues = $('.blockCompare-tableValues', compare.$compare),
        $rowProperty,
        $rowValue,
        $rowPropertyH,
        $rowValueH; // Tables properties and values resize

    $('tr', $tProperties).each(function (i) {
      $rowProperty = $(this);
      $rowPropertyH = $rowProperty.height();
      $rowValue = $('tr', $tValues).eq(i);
      $rowValueH = $rowValue.height();

      if ($rowPropertyH > $rowValueH) {
        $rowProperty.add($rowValue).height($rowPropertyH);
      } else {
        $rowProperty.add($rowValue).height($rowValueH);
      }
    });
    compare.$scrollPaneAPI.reinitialise();

    if (compare.$scrollPane.hasClass('jspScrollable')) {
      compare.$scrollPane.find('.jspContainer').height($tProducts.height() + 60);
    } else {
      compare.$scrollPane.find('.jspContainer').height($tProducts.height());
    }

    $headH = compare.$head.height(); // Table products resize

    compare.$compare.css({
      paddingTop: $headH
    });
    compare.$head.data({
      parentH: compare.$compare.innerHeight(),
      start: $headPosition.top,
      height: $headH
    });
  },
  headPosition: function headPosition($position) {
    var $params = {};

    if ($position == 'fixed') {
      $params = {
        position: $position,
        zIndex: 10,
        top: 0,
        bottom: 'auto',
        left: 'auto'
      };
    } else if ($position == 'bottom') {
      $params = {
        position: 'absolute',
        zIndex: 10,
        top: 'auto',
        bottom: 0,
        left: 'auto'
      };
    } else {
      $params = {
        position: 'absolute',
        zIndex: 10,
        top: 0,
        bottom: 'auto',
        left: 0
      };
    }

    compare.$head.css($params);
  },
  onPageScroll: function onPageScroll($scrollTop) {
    var $startLine = compare.$head.data('start'),
        $headH = compare.$head.data('height'),
        $stopLine = compare.$head.data('parentH') + $startLine - $headH;

    if ($startLine <= $scrollTop) {
      if ($scrollTop >= $stopLine) {
        compare.headPosition('bottom');
      } else {
        compare.headPosition('fixed');
      }
    } else {
      compare.headPosition();
    }
  }
}; // -------------------------------------
// Tooltip
// -------------------------------------

var tooltip = {
  init: function init(settings) {
    tooltip.config = {
      tooltip: $('<div class="tooltip" />')
    };
    $.extend(tooltip.config, settings);
    tooltip.setup();
  },
  setup: function setup() {
    tooltip.$tooltip = tooltip.config.tooltip;
    tooltip.$tooltip.appendTo('body');
    $(document).on({
      mouseenter: tooltip.show,
      mouseleave: tooltip.hide
    }, '[data-tooltip]');
  },
  show: function show() {
    var $toggle = $(this),
        $toggleH = $toggle.innerHeight(),
        $togglePos = $toggle.offset(),
        $content = $toggle.data('tooltip');

    if (!$content) {
      return;
    }

    tooltip.$tooltip.html($content).css({
      top: $togglePos.top + $toggleH + 9,
      left: $togglePos.left - 7
    }).show();
  },
  hide: function hide() {
    tooltip.$tooltip.hide();
  }
};
var tooltip2 = {
  init: function init(settings) {
    tooltip2.config = {
      tooltip2: $('<div class="tooltip2" />')
    };
    $.extend(tooltip2.config, settings);
    tooltip2.setup();
  },
  setup: function setup() {
    tooltip2.$tooltip2 = tooltip2.config.tooltip2;
    tooltip2.$tooltip2.appendTo('body');
    $(document).on({
      mouseenter: tooltip2.show,
      mouseleave: tooltip2.hide
    }, '[data-tooltip2]');
  },
  show: function show() {
    var $toggle = $(this),
        $toggleH = $toggle.innerHeight(),
        $togglePos = $toggle.offset(),
        $content = $toggle.data('tooltip2');

    if (!$content) {
      return;
    }

    tooltip2.$tooltip2.html($content).css({
      top: $togglePos.top + $toggleH + 9,
      left: $togglePos.left - 1
    }).show();
  },
  hide: function hide() {
    tooltip2.$tooltip2.hide();
  }
}; // -------------------------------------
// On scroll
// -------------------------------------

function ifScroll() {
  var scrollTop = $(window).scrollTop();
} // -------------------------------------
// Form
// -------------------------------------


function formFunc() {
  //$('select').selectbox();
  $('input[placeholder], textarea[placeholder]').placeholder();
} // -------------------------------------


$(function () {
  var win = $(window); // Teasers

  $('.teasers').each(function () {
    var teasers = $(this);
    teasers.on({
      mouseenter: function mouseenter() {
        $(this).parents('.teasers-item').addClass('hover');
      },
      mouseleave: function mouseleave() {
        $(this).parents('.teasers-item').removeClass('hover');
      }
    }, '.teasers-image a, .teasers-title a');
  }); // Form

  formFunc(); // Compare

  if ($('.blockCompare').length) {
    compare.init();
  } // Tooltip


  tooltip.init(); //On window resize
  //$(window).on( 'resize', $.throttle( 150, ifResize ) ).trigger( 'resize' );

  win.on('scroll', $.throttle(50, ifScroll));

  if (svg4everybody) {
    svg4everybody();
  }
});