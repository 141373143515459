"use strict";

$(document).ready(function () {
  // "User Logged In" subscription
  $(document).on('userLoggedIn', function () {
    pt.user.reloadUserInfo();
    pt.cookie.set('dontshowsubscribepopup', true);

    if (pt.comment.needAddCommentAfterLogin) {
      pt.comment.addComment(function () {
        pt.comment.needAddCommentAfterLogin = false;
      });
    }
  }); // Comments Block initialization

  pt.comment.init();
  pt.subscribe.init();
});
pt = {
  userLogged: false
};
pt.subscribe = {
  category: '',
  popup: null,
  emailContainer: null,
  setChecked: function setChecked() {
    var checkbox = this.popup.find('[name="' + this.category.toLowerCase() + '"]');
    checkbox.prop('checked', true);
  },
  init: function init() {
    var me = this;

    if (!pt.cookie.get('subscribed')) {
      pt.cookie.set('subscribed', '');
    }

    me.category = $('#subscribeCategory').val();

    if (!me.category) {
      return;
    }

    me.popup = $('#overlay-subscribe');

    if (!me.popup.length) {
      return;
    }

    this.setChecked();
    me.emailContainer = $('#subscribeEmail'); //$('#subscribeCategoryContainer').html(me.category);

    $('#subscribeClose').click(function () {
      var today = new Date(),
          showDate = new Date(today);
      me.popup.hide();
      showDate.setDate(today.getDate() + 3); // Ставим куку на минуту (для тестирования)
      // showDate.setTime(showDate.getTime() + 1000 * 60);

      pt.cookie.set('dontshowsubscribepopup', true, {
        expires: showDate
      });
    });
    $('#btnSubscribe').click(function () {
      var email = me.emailContainer.val(),
          errorsContainer = $(this).parents('.newOverlay-content').find('.validation-summary-errors ul'),
          infoContainer = $(this).parents('.form-footer').find('p.subscribeInfo'),
          checked,
          categories = '';
      errorsContainer.empty();

      if (!email) {
        errorsContainer.append('<li>Необходимо указать Email</li>');
        return;
      }

      checked = me.popup.find('input[type=checkbox]:checked');

      if (!checked || checked.length === 0) {
        errorsContainer.append('<li>Необходимо выбрать хотя бы одну категорию</li>');
        return;
      }

      checked.each(function (index, item) {
        categories += item.name + ',';
      });
      categories = categories.substring(0, categories.length - 1);
      $.ajax({
        url: '/subscribe/add',
        method: 'POST',
        data: {
          email: email,
          category: categories
        },
        dataType: 'json',
        success: function success(data) {
          if (data.success) {
            pt.cookie.set('subscribed', 'true', {
              path: '/'
            });
            infoContainer.html(data.message);
            me.popup.hide(); //me.emailContainer.val('');
          } else {
            errorsContainer.html('<li>' + data.message + '</li>');
          }
        }
      });
    });

    if (this.needToShow()) {
      this.show();
    }
  },
  needToShow: function needToShow() {
    var loc = location.href;

    if (loc.lastIndexOf('/') === loc.length - 1) {
      loc = loc.substr(0, loc.length - 1);
    } else {
      var isFile = loc.substr(loc.lastIndexOf('/'), loc.length).indexOf('.') !== -1;
      if (isFile) loc = loc.substr(0, loc.lastIndexOf('/'));
    }

    var targetValue = loc.substr(loc.lastIndexOf('/') + 1);

    if (targetValue === "katalog" || targetValue.indexOf("katalog?") !== -1) {
      return false;
    }

    if (pt.cookie.get('dontshowsubscribepopup')) {
      return false;
    }

    return !pt.userLogged && pt.cookie.get('subscribed') !== 'true';
  },
  show: function show() {
    var me = this;
    setTimeout(function () {
      me.popup.show();
    }, 60000);
  }
};
pt.cookie = {
  get: function get(name) {
    var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  },
  set: function set(name, value, options) {
    options = options || {};
    var expires = options.expires;

    if (typeof expires == "number" && expires) {
      var date = new Date();
      date.setTime(date.getTime() + expires * 1000);
      expires = options.expires = date;
    }

    if (expires && expires.toUTCString) {
      options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);
    var updatedCookie = name + "=" + value;

    for (var propName in options) {
      if (options.hasOwnProperty(propName)) {
        updatedCookie += "; " + propName;
        var propValue = options[propName];

        if (propValue !== true) {
          updatedCookie += "=" + propValue;
        }
      }
    }

    document.cookie = updatedCookie;
  },
  del: function del(name) {
    set(name, "", {
      expires: -1
    });
  }
};
pt.recaptcha = {
  isLoaded: false,
  render: function render() {
    $.each($('.g-recaptcha'), function (index, item) {
      window.grecaptcha.render(item, {
        'sitekey': '6LdYZAoTAAAAACEBPs5XjvbmfdtpI4hwdZaD6m1B'
      });
    });
  },
  loaded: function loaded() {
    this.isLoaded = true;
  }
};
pt.comment = {
  relateId: 0,
  type: 0,
  page: 1,
  text: '',
  parent: null,
  errorsContainer: null,
  needAddCommentAfterLogin: false,
  init: function init() {
    var commentsContainer = $('#comments');

    if (commentsContainer.length > 0 && jQuery.trim(commentsContainer.html()) === "") {
      this.relateId = commentsContainer.data("relateid");
      this.type = commentsContainer.data("commenttype");
      this.reloadComments();
    }
  },
  addSuccess: function addSuccess() {
    pt.user.reloadUserInfo();
    pt.recaptcha.render();
    pt.comment.initReply();
  },
  changePage: function changePage(page) {
    this.page = page;
    this.reloadComments();
  },
  subscriptions: function subscriptions() {
    var me = this;
    $('#btnAddCommentUnAuth').on('click', function () {
      var btn = $(this),
          userNameField = btn.parents('.newOverlay-content').find('#UserName'),
          recapthcaResponseField = btn.parents('.newOverlay-content').find('.g-recaptcha-response'),
          isValid = true;
      me.errorsContainer = btn.parents('.newOverlay-content').find('.validation-summary-errors ul');
      me.errorsContainer.empty();

      if (!userNameField.val()) {
        isValid = false;
        me.errorsContainer.append('<li>Необходимо заполнить поле "Имя"!</li>');
      }

      if (!recapthcaResponseField.val()) {
        isValid = false;
        me.errorsContainer.append('<li>Необходимо отметить поле "Я не робот"!</li>');
      }

      if (!isValid) {
        return;
      }

      $.ajax({
        url: '/comment/addcommentunauth',
        method: 'POST',
        data: {
          text: me.text,
          parentId: me.parent,
          relateId: me.relateId,
          type: me.type,
          pagenumber: me.page,
          userName: userNameField.val(),
          recaptcha: recapthcaResponseField.val()
        },
        dataType: 'json',
        success: function success(data) {
          if (data.success) {
            me.reloadComments();
            me.clear();
          } else {
            me.errorsContainer.html('<li>' + data.message + '</li>');
          }
        }
      });
    });
    $('.btnAddComment').on('click', function () {
      var btn = $(this),
          textarea = btn.parents('.form.formBlock').find('textarea'),
          parent = btn.parents('.form.formBlock').find('[name=ParentId]');
      me.errorsContainer = btn.parents('.form.formBlock').find('.validation-summary-errors ul');
      me.errorsContainer.empty();

      if (!textarea.val()) {
        me.errorsContainer.append('<li>Отзыв не может быть пустым!</li>');
        return;
      }

      me.text = textarea.val();
      me.parent = parent.val();
      me.addComment();
    });
    $('.reply-btn').on('click', function () {
      var block = $(this).data('id');
      $('span#commentInfo', block).remove();
      $(block).toggle('slow');
      return false;
    });
  },
  addComment: function addComment(callback) {
    var me = this;
    $.ajax({
      url: '/comment/addcomment',
      method: 'POST',
      data: {
        text: me.text,
        parentId: me.parent,
        relateId: me.relateId,
        type: me.type,
        pagenumber: me.page
      },
      dataType: 'json',
      success: function success(data) {
        if (data.success) {
          me.reloadComments();
          me.clear();

          if (callback) {
            callback();
          }
        } else if (data.needAuthorize) {
          me.needAddCommentAfterLogin = true;
          $('#overlay-commentauthorization').show();
          pt.recaptcha.render();
        } else {
          me.errorsContainer.html('<li>' + data.message + '</li>');
        }
      }
    });
  },
  clear: function clear() {
    this.text = '';
    this.parent = null;
    this.errorsContainer = null;
  },
  reloadComments: function reloadComments() {
    var me = this; //#warning Временно выключено

    $.ajax({
      url: '/comment/getcomments',
      data: {
        relateId: me.relateId,
        type: me.type,
        page: me.page
      },
      dataType: 'html',
      success: function success(data) {
        $('#comments').html(data);
        $('.commentPaginator').click(function () {
          pt.comment.changePage($(this).data('page'));
        });
        pt.func.initOverlay();
        pt.comment.subscriptions();
      }
    });
  }
};
pt.func = {
  initOverlay: function initOverlay() {
    $('.close').click(function () {
      $(this).closest('.newOverlay').hide(0);
      return false;
    });
    $('.newoverlay-show').click(function () {
      $('.newOverlay').hide();
      $($(this).data('target')).show();
      return false;
    });
  }
};
pt.user = {
  reloadUserInfo: function reloadUserInfo() {
    $.ajax({
      url: '/Account/RenderUserInfo',
      dataType: 'html',
      success: function success(data) {
        $('#userinfo.account').html(data);
        pt.userLogged = true;
        pt.func.initOverlay();
      }
    });
  }
};