"use strict";

sales = {
  productId: 0,
  yandexProductId: 0,
  productName: '',
  regionId: 0,
  isRobot: false,
  isTouch: false,
  colors: $('.select_color'),
  modifications: $('.select_modification'),
  buyButton: $('#buy_button'),
  init: function init(productId, yandexProductId, productName, isTouch, isRobot) {
    var me = this;
    me.productId = productId;
    me.yandexProductId = yandexProductId;
    me.productName = productName;
    me.isTouch = isTouch;
    me.isRobot = isRobot;
    me.colors.change(function () {
      var selected = me.colors.select("option:selected").first();
      me.loadOffer(selected.val());
    });
    me.modifications.change(function () {
      location.href = this.value + "/kupit-v-moskve";
    });
    me.hubInit();
    me.loadOffer();
  },
  loadOffer: function loadOffer(color) {
    var me = this,
        regionId = 213,
        sort = 'PRICE',
        order = 'ASC',
        filters = {
      InSale: true,
      Delivery: 'all',
      Color: color
    };
  },
  colorsDropDownFill: function colorsDropDownFill(filters, activeFilters) {
    var me = this;

    if (filters.length > 0) {
      var activeColorFilter = $.grep(activeFilters, function (f, i) {
        return f.Type === 'COLOR';
      });
      var selectedColorId = activeColorFilter.length > 0 && activeColorFilter[0].Values.length > 0 ? activeColorFilter[0].Values[0].Id : 0;

      for (var cnt = 0; cnt < filters.length; cnt++) {
        var f = filters[cnt];

        if (f.Type === 'COLOR') {
          f.Values.forEach(function (fv) {
            me.colors.append($("<option" + (selectedColorId === fv.Id ? " selected=selected" : "") + "></option>").attr("value", fv.Id).html("<div style='width:13px;height:13px;background-color:" + fv.Color + "'></div>" + fv.Name));
          });
        }
      }
    }
  },
  buyButtonFill: function buyButtonFill(url, price, modelId) {
    var me = this;
    me.buyButton.attr('target', '_blank');
    me.buyButton.attr('rel', 'nofollow');

    if (price) {
      me.buyButton.attr('href', url);
      me.buyButton.attr('data-model-id', modelId);
      me.buyButton.addClass('target-blank');
      me.buyButton.removeClass('cursor-default');
      me.buyButton.html('Купить за ' + price + ' <i class="icon-rouble"></i>');
    } else {
      me.buyButton.attr('href', '#');
      me.buyButton.attr('data-model-id', 0);
      me.buyButton.addClass('target-blank');
      me.buyButton.removeClass('cursor-default');
      me.buyButton.html('нет цен');
    }
  }
};