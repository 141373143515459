"use strict";

var menuArrows = ['<svg class="icon icon-arrow-menu"><use xlink:href="/img/sprite.svg#icon-arrow-menu"></use></svg>', '<svg class="icon icon-arrow-menu"><use xlink:href="/img/sprite.svg#icon-arrow-menu"></use></svg>'];

function createCarousel(wrap) {
  wrap.owlCarousel({
    items: 1,
    nav: true,
    navText: menuArrows,
    navElement: 'button',
    dots: false
  });
}

function render(items, wrap, drop, dropGroup) {
  var wrapWidth = wrap.width();
  var sum = 0,
      i = 0;
  wrap.find('.owl-nav').remove();

  for (var index = 0; index < items.length; index++) {
    var _this = $(items[index]);

    _this.attr('data-count', index);

    _this.attr('data-group', i);

    sum += _this.width();

    if (sum >= wrapWidth) {
      _this.attr('data-group', i + 1);

      sum = 0;
      sum += _this.width();
      $('[data-group="' + i + '"]').wrapAll('<div class="menu__group js-menu-group"></div>');
      i++;
    }
  }

  $('[data-group="' + i + '"]').wrapAll('<div class="menu__group js-menu-group"></div>');
  createCarousel(wrap);
  $.each(items, function (i, el) {
    var item = $(el);
    item.on('mouseenter', function () {
      var index = item.data('count');
      items.removeClass('active');
      item.addClass('active');
      drop.show();
      dropGroup.hide();
      dropGroup.eq(index).show();
    });
    item.on('touchstart', function () {
      var index = item.data('count');

      if (item.hasClass('active')) {
        items.removeClass('active');
        drop.hide();
      } else {
        items.removeClass('active');
        item.addClass('active');
        drop.show();
        dropGroup.hide();
        dropGroup.eq(index).show();
      }
    });
  });
  $('.owl-nav button').click(function () {
    items.removeClass('active');
    drop.hide();
  });
}

$(function () {
  var menu = $('.js-menu');
  var wrap = menu.find('.js-menu-wrap');
  var drop = menu.find('.js-menu-drop'),
      dropGroup = menu.find('.js-menu-drop-group');
  var items = $('.js-menu-item');
  $(window).on('resize', function () {
    wrap.trigger('destroy.owl.carousel');
    wrap.removeClass('owl-loaded');
    wrap.empty();

    for (var i = 0; i < items.length; i++) {
      var item = $(items[i]);
      item.removeAttr('data-group');
      wrap.append(item);
    }

    render(items, wrap, drop, dropGroup);
  });
  render(items, wrap, drop, dropGroup);
  menu.on('mouseleave', function () {
    items.removeClass('active');
    drop.hide();
  });
});