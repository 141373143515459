"use strict";

catManager = {
  overlay: $('.js-m-overlay'),
  select: $('.js-m-select'),
  wrapper: $('.js-m-wrapper'),
  filters: $('.js-m-filters'),
  filtersD: $('.filters'),
  filterInStock: $('.check__input__instock'),
  mFilterInStock: $('.m-check__input__instock'),
  showFilters: $('.js-m-show-filters'),
  hideFilters: $('.js-m-hide-filters'),
  showMoreFiltersBtn: $("#filters__more-link"),
  showLessFiltersBtn: $("#filters__less-link"),
  showMoreFiltersBtnMobile: $("#filters__more-link-mobile"),
  showLessFiltersBtnMobile: $("#filters__less-link-mobile"),
  productsHtml: $(".prod_list"),
  prodEl: $(".l-content"),
  totalCount: parseInt($(".tabs .tabs__counter").text().replace("(", "").replace(")", "")),
  tabsCounter: $('.tabs__counter'),
  metaTitleCategory: '',
  categoryName: '',
  MaxBrandsNumber: 20,
  priceMin: $('input[name=filter-cost-from]'),
  priceMax: $('input[name=filter-cost-to]'),
  mpriceMin: $('input[name=mfilter-cost-from]'),
  mpriceMax: $('input[name=mfilter-cost-to]'),
  brandInput: $('input[name=filter-brand]'),
  mbrandInput: $('input[name=mfilter-brand]'),
  brandScroll: $('.filters__wrap_scroll'),
  mbrandScroll: $('.m-filters__wrap_scroll'),
  resultButton: $('#result_button'),
  loadButton: $('#more_button'),
  page: 1,
  filtersData: {},
  count: 30,
  query: url('?'),
  tmpl: $.templates("#productTmpl"),
  message: $.templates("#messageTmpl"),
  category: $(".head__title"),
  pagination: null,
  inStock: false,
  init: function init(metaTitleCategory, categoryName, page, region, inStock) {
    var me = this;
    this.region = region;
    this.metaTitleCategory = metaTitleCategory;
    this.categoryName = categoryName;
    this.page = page;
    this.inStock = inStock;

    if (this.query !== undefined) {
      me.initFiltersQueryData();
    }

    $.views.tags("ratingBlue", me.renderBlueStars);
    me.buttonsInit();
    me.filtersInit();
    var paginationDiv = $('#pagination');

    if (paginationDiv.length) {
      me.pagination = paginationDiv.pagination({
        items: me.totalCount,
        itemsOnPage: me.count,
        currentPage: page,
        cssStyle: 'light-theme',
        hrefTextPrefix: '#',
        edges: 0,
        prevText: ' ',
        nextText: ' ',
        onPageClick: function onPageClick(page) {
          me.inputFilters(page);
          $(window).scrollTo(0, 500);
        }
      });
    }
  },
  updatePagination: function updatePagination(page, itemsCount) {
    var me = this;
    console.log(itemsCount);

    if (me.pagination) {
      if (itemsCount < 2) {
        $('#pagination').hide();
      } else {
        $('#pagination').show();
      }

      if (page) me.pagination.pagination('drawPage', page);
      if (itemsCount) me.pagination.pagination('updateItems', itemsCount);
    }
  },
  filtersInit: function filtersInit() {
    var me = this;
    if (me.filterInStock) me.filterInStock.prop('checked', me.inStock);
    me.mFilterInStock.prop('checked', me.inStock);
    me.priceMin.delayKeyup(function (el) {
      el.val(el.val().replace(/[^\d]/, ''));
      me.mpriceMin.val(el.val());
      me.inputFilters();
    }, 1500);
    me.mpriceMin.delayKeyup(function (el) {
      el.val(el.val().replace(/[^\d]/, ''));

      if (me.priceMin.length > 0) {
        me.priceMin.val(el.val());
      }

      me.inputFilters();
    }, 1500);
    me.priceMax.delayKeyup(function (el) {
      el.val(el.val().replace(/[^\d]/, ''));
      me.mpriceMax.val(el.val());
      me.inputFilters();
    }, 1500);
    me.mpriceMax.delayKeyup(function (el) {
      el.val(el.val().replace(/[^\d]/, ''));

      if (me.priceMax.length > 0) {
        me.priceMax.val(el.val());
      }

      me.inputFilters();
    }, 1500);
    $('.check__input').bind('change', function () {
      var index = $(this).attr('data-index');
      me.filters.find('.m-check__input[data-index="' + index + '"]').prop('checked', $(this).prop('checked'));
      me.inputFilters();
      return false;
    });
    me.filterInStock.bind('change', function () {
      me.inStock = $(this).prop('checked').toString();
      me.inputFilters();
      return false;
    });
    me.mFilterInStock.bind('change', function () {
      me.inStock = $(this).prop('checked').toString();
      me.inputFilters();
      return false;
    });
    $('.m-check__input').bind('change', function () {
      var index = $(this).attr('data-index');

      if (me.filtersD.length > 0) {
        me.filtersD.find('.check__input[data-index="' + index + '"]').prop('checked', $(this).prop('checked'));
      }

      me.inputFilters();
      return false;
    });
    me.brandInput.bind('keyup', function () {
      var value = $(this).val().toLowerCase(),
          count = 0;
      $(".check__input").each(function () {
        var checkValue = $(this).attr('data-title').toLowerCase();

        if (checkValue.indexOf(value) === 0) {
          count++;
          $(this).parent().removeClass("hidden");
        } else {
          $(this).parent().addClass("hidden");
        }
      });

      if (count <= 16) {
        me.brandInput.parent().parent().parent().removeClass('filters__item_brand');

        if (me.brandScroll.hasClass('filters__wrap_scroll-yes')) {
          me.showLessFiltersBtn.hide();
          me.showLessFiltersBtnMobile.hide();
        } else {
          me.showMoreFiltersBtn.hide();
          me.showMoreFiltersBtnMobile.hide();
        }
      } else {
        me.brandInput.parent().parent().parent().addClass('filters__item_brand');

        if (me.brandScroll.hasClass('filters__wrap_scroll-yes')) {
          me.showLessFiltersBtn.show();
          me.showLessFiltersBtnMobile.show();
        } else {
          me.showMoreFiltersBtn.show();
          me.showMoreFiltersBtnMobile.show();
        }
      }

      return false;
    });
    me.mbrandInput.bind('keyup', function () {
      var value = $(this).val().toLowerCase(),
          count = 0;

      if (me.brandInput.length > 0) {
        $(".check__input").each(function () {
          var checkValue = $(this).attr('data-title').toLowerCase();

          if (checkValue.indexOf(value) === 0) {
            $(this).parent().removeClass("hidden");
          } else {
            $(this).parent().addClass("hidden");
          }
        });
      }

      $(".m-check__input").each(function () {
        var checkValue = $(this).attr('data-title').toLowerCase();

        if (checkValue.indexOf(value) === 0) {
          count++;
          $(this).parent().removeClass("hidden");
        } else {
          $(this).parent().addClass("hidden");
        }
      });

      if (count <= 16) {
        me.mbrandInput.parent().parent().parent().removeClass('m-filters__item_brand');

        if (me.mbrandScroll.hasClass('m-filters__wrap_scroll-yes')) {
          if (me.brandInput.length > 0) {
            me.showLessFiltersBtn.hide();
          }

          me.showLessFiltersBtnMobile.hide();
        } else {
          if (me.brandInput.length > 0) {
            me.showMoreFiltersBtn.hide();
          }

          me.showMoreFiltersBtnMobile.hide();
        }

        if (me.brandInput.length > 0) {
          me.brandInput.parent().parent().parent().removeClass('filters__item_brand');
        }
      } else {
        me.mbrandInput.parent().parent().parent().addClass('m-filters__item_brand');

        if (me.mbrandScroll.hasClass('m-filters__wrap_scroll-yes')) {
          if (me.brandInput.length > 0) {
            me.showLessFiltersBtn.show();
          }

          me.showLessFiltersBtnMobile.show();
        } else {
          if (me.brandInput.length > 0) {
            me.showMoreFiltersBtn.show();
          }

          me.showMoreFiltersBtnMobile.show();
        }

        if (me.brandInput.length > 0) {
          me.brandInput.parent().parent().parent().addClass('filters__item_brand');
        }
      }

      return false;
    });
  },
  buttonsInit: function buttonsInit() {
    var me = this;
    me.select.each(function () {
      var that = $(this),
          head = that.find('.js-m-select-head'),
          title = that.find('.js-m-select-title'),
          option = that.find('.js-m-select-option');
      head.on('click', function (e) {
        e.preventDefault();
        that.addClass('open');
        me.overlay.show();
      });
      option.on('click', function () {
        var value = $(this).html(),
            button = $('.sort__list > a').find('button[data-filter="' + $(this).data('filter') + '"]');
        that.removeClass('open');
        title.html(value);
        me.overlay.hide();

        if (button.length > 0) {
          if (button.hasClass('active')) {
            if (button.hasClass('active-down')) {
              button.removeClass('active-down');
              button.addClass('active-up');
            } else {
              button.removeClass('active-up');
              button.addClass('active-down');
            }
          } else if (button.data('filter') === "price") {
            if (button.hasClass('active-down')) {
              button.removeClass('active-down');
            } else {
              button.removeClass('active-up');
            }

            button.parent().siblings().find('button').removeClass('active');
            button.addClass('active');
            button.addClass('active-up');
          } else if (button.data('filter') === "rating") {
            if (button.hasClass('active-down')) {
              button.removeClass('active-down');
            } else {
              button.removeClass('active-up');
            }

            button.parent().siblings().find('button').removeClass('active');
            button.addClass('active');
            button.addClass('active-down');
          }
        }

        if ($(this).hasClass('active')) {
          if ($(this).hasClass('active-down')) {
            $(this).removeClass('active-down');
            $(this).addClass('active-up');
          } else {
            $(this).removeClass('active-up');
            $(this).addClass('active-down');
          }
        } else if ($(this).data('filter') === "price") {
          if ($(this).hasClass('active-down')) {
            $(this).removeClass('active-down');
          } else {
            $(this).removeClass('active-up');
          }

          option.removeClass('active');
          $(this).addClass('active');
          $(this).addClass('active-up');
        } else if ($(this).data('filter') === "rating") {
          if ($(this).hasClass('active-down')) {
            $(this).removeClass('active-down');
          } else {
            $(this).removeClass('active-up');
          }

          option.removeClass('active');
          $(this).addClass('active');
          $(this).addClass('active-down');
        }

        me.inputFilters();
        return false;
      });
    });
    me.overlay.on('click', function (e) {
      e.preventDefault();
      me.select.removeClass('open');
      me.overlay.hide();
    });
    me.resultButton.on('click', function (e) {
      e.preventDefault();
      me.wrapper.removeClass('hide');
      me.filters.removeClass('show');
    });
    me.loadButton.on('click', function (e) {
      e.preventDefault();
      me.page++;
      me.generateFiltersData();
      me.loadProducts();
    });
    me.showFilters.on('click', function (e) {
      e.preventDefault();
      me.wrapper.addClass('hide');
      me.filters.addClass('show');
      $(document).scrollTop(0);
    });
    me.hideFilters.on('click', function (e) {
      e.preventDefault();
      me.wrapper.removeClass('hide');
      me.filters.removeClass('show');
    });
    me.showMoreFiltersBtn.on('click', function () {
      me.brandScroll.addClass('filters__wrap_scroll-yes');
      $(this).hide();
      me.brandInput.focus();
      me.showLessFiltersBtn.show();
      me.showMoreFiltersBtnMobile.hide();
      me.showLessFiltersBtnMobile.show();
      return false;
    });
    me.showMoreFiltersBtnMobile.on('click', function () {
      me.mbrandScroll.addClass('m-filters__wrap_scroll-yes');
      $(this).hide();

      if (me.showLessFiltersBtn.length > 0) {
        me.brandScroll.addClass('filters__wrap_scroll-yes');
        me.showLessFiltersBtn.show();
        me.showMoreFiltersBtn.hide();
      }

      me.showLessFiltersBtnMobile.show();
      return false;
    });
    me.showLessFiltersBtn.on('click', function () {
      var count = 0,
          brandParent = me.brandInput.parent().parent().parent();
      me.brandScroll.removeClass('filters__wrap_scroll-yes');
      $(this).hide();
      me.brandInput.val('');
      $(".check__input").each(function () {
        count++;
        $(this).parent().removeClass("hidden");
      });

      if (count > 16 && !brandParent.hasClass('filters__item_brand')) {
        brandParent.addClass('filters__item_brand');
      }

      me.showLessFiltersBtnMobile.hide();
      me.showMoreFiltersBtn.show();
      me.showMoreFiltersBtnMobile.show();
      return false;
    });
    me.showLessFiltersBtnMobile.on('click', function () {
      var count = 0,
          mbrandParent = me.mbrandInput.parent().parent().parent();
      me.mbrandScroll.removeClass('m-filters__wrap_scroll-yes');
      $(this).hide();

      if (me.showLessFiltersBtn.length > 0) {
        me.brandScroll.removeClass('filters__wrap_scroll-yes');
        me.showLessFiltersBtn.hide();
        me.brandInput.val('');
        $(".check__input").each(function () {
          $(this).parent().removeClass("hidden");
        });
        me.showMoreFiltersBtn.show();
      }

      me.mbrandInput.val('');
      $(".m-check__input").each(function () {
        count++;
        $(this).parent().removeClass("hidden");
      });

      if (count > 16 && !mbrandParent.hasClass('m-filters__item_brand')) {
        mbrandParent.addClass('m-filters__item_brand');
      }

      if (me.showLessFiltersBtn.length > 0) {
        var brandParent = me.brandInput.parent().parent().parent();

        if (count > 16 && !brandParent.hasClass('filters__item_brand')) {
          brandParent.addClass('filters__item_brand');
        }
      }

      me.showMoreFiltersBtnMobile.show();
      return false;
    });
    me.brandScroll.on('scroll', function (e) {
      var that = this,
          brandParent = me.brandInput.parent().parent().parent(),
          ref = 0;
      ref = that.scrollTopMax != null ? that.scrollTopMax : that.scrollHeight - that.clientHeight;

      if (that.scrollTop > ref - 20) {
        brandParent.removeClass('filters__item_brand');
      } else {
        brandParent.addClass('filters__item_brand');
      }

      if (that.scrollTop === 0) {
        brandParent.removeClass('filters__item_brand-top');
      } else {
        brandParent.addClass('filters__item_brand-top');
      }
    });
    me.mbrandScroll.on('scroll', function (e) {
      var that = this,
          brandParent = me.mbrandInput.parent().parent().parent(),
          ref = 0;
      ref = that.scrollTopMax != null ? that.scrollTopMax : that.scrollHeight - that.clientHeight;

      if (that.scrollTop > ref - 20) {
        brandParent.removeClass('m-filters__item_brand');
      } else {
        brandParent.addClass('m-filters__item_brand');
      }

      if (that.scrollTop === 0) {
        brandParent.removeClass('m-filters__item_brand-top');
      } else {
        brandParent.addClass('m-filters__item_brand-top');
      }
    });
    $('.sort__list > a').on('click', function (e) {
      e && e.preventDefault();
      var link = $(this),
          button = link.find('button'),
          option = me.select.find('.js-m-select-option[data-filter="' + button.data('filter') + '"]'),
          title = me.select.find('.js-m-select-title');

      if (button.hasClass('active')) {
        if (button.hasClass('active-down')) {
          button.removeClass('active-down');
          button.addClass('active-up');
          option.removeClass('active-down');
          option.addClass('active-up');
        } else {
          button.removeClass('active-up');
          button.addClass('active-down');
          option.removeClass('active-up');
          option.addClass('active-down');
        }
      } else if (button.data('filter') === "price") {
        if (button.hasClass('active-down')) {
          button.removeClass('active-down');
          option.removeClass('active-down');
        } else {
          button.removeClass('active-up');
          option.removeClass('active-up');
        }

        link.siblings().find('button').removeClass('active');
        option.siblings().removeClass('active');
        button.addClass('active');
        button.addClass('active-up');
        option.addClass('active');
        option.addClass('active-up');
      } else if (button.data('filter') === "rating") {
        if (button.hasClass('active-down')) {
          button.removeClass('active-down');
          option.removeClass('active-down');
        } else {
          button.removeClass('active-up');
          option.removeClass('active-up');
        }

        link.siblings().find('button').removeClass('active');
        option.siblings().removeClass('active');
        button.addClass('active');
        button.addClass('active-down');
        option.addClass('active');
        option.addClass('active-down');
      }

      title.html(option.html());
      me.inputFilters();
    });
  },
  inputFilters: function inputFilters(page) {
    var me = this;
    me.page = page ? page : 1;
    me.totalCount = 0;
    me.generateFiltersData();
    me.loadProducts(me.page);
  },
  initFiltersQueryData: function initFiltersQueryData() {
    var me = this;

    if (me.query.priceMin !== '') {
      me.filtersData.priceMin = me.query.priceMin;

      if (me.priceMin.length > 0) {
        me.priceMin.val(me.filtersData.priceMin);
      }

      me.mpriceMin.val(me.filtersData.priceMin);
    }

    if (me.query.priceMax !== '') {
      me.filtersData.priceMax = me.query.priceMax;

      if (me.priceMax.length > 0) {
        me.priceMax.val(me.filtersData.priceMax);
      }

      me.mpriceMax.val(me.filtersData.priceMin);
    }

    if (me.query.companies) {
      var companies = me.query.companies.split(',').map(function (x) {
        return x.toLowerCase();
      });

      for (var j = 0; j < companies.length; j++) {
        if (me.filtersD.length > 0) {
          $('.check__input[data="' + companies[j] + '"]').prop('checked', true);
        }

        $('.m-check__input[data="' + companies[j] + '"]').prop('checked', true);
      }
    }

    if (me.query.inStock) {
      if (me.filterInStock) me.filterInStock.prop('checked', me.query.inStock == "true");
      me.mFilterInStock.prop('checked', me.query.inStock == "true");
    }
  },
  generateFiltersData: function generateFiltersData() {
    var me = this,
        brands = '';

    if (me.filtersD.length > 0) {
      me.filtersData.priceMin = me.priceMin.val();
      me.filtersData.priceMax = me.priceMax.val();
      $('.check__input:checked').each(function () {
        brands += $(this).attr('data-title') + ',';
      });
      var buttonD = $(".sort__btn.active");
      me.filtersData.sortBy = buttonD.data('filter');
      me.filtersData.sortDesc = buttonD.hasClass("active-down") ? "True" : "False";
    } else {
      me.filtersData.priceMin = me.mpriceMin.val();
      me.filtersData.priceMax = me.mpriceMax.val();
      $('.m-check__input:checked').each(function () {
        brands += $(this).attr('data-title') + ',';
      });
      var button = $(".m-select__option.active");
      me.filtersData.sortBy = button.data('filter');
      me.filtersData.sortDesc = button.hasClass("active-down") ? "True" : "False";
    }

    if (brands.length) {
      brands = brands.substring(0, brands.length - 1);
    }

    me.filtersData.inStock = me.inStock;
    me.filtersData.companies = brands;
    me.filtersData.count = me.count;
    me.filtersData.page = me.page;
    me.filtersData.region = me.region;
    var keys = Object.keys(me.filtersData);

    for (var i = 0; i < keys.length; i++) {
      if (!me.filtersData[keys[i]]) {
        delete me.filtersData[keys[i]];
      }
    }

    var search = Object.keys(me.filtersData).map(function (k) {
      return encodeURIComponent(k) + '=' + me.filtersData[k];
    }).join('&');
    history.pushState('', 'Каталог', window.location.pathname + '?' + search);
  },
  declOfNum: function () {
    var cases = [2, 0, 1, 1, 1, 2];

    var declOfNumSubFunction = function declOfNumSubFunction(titles, number) {
      number = Math.abs(number);
      return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
    };

    return function (_titles) {
      if (arguments.length === 1) {
        return function (_number) {
          return declOfNumSubFunction(_titles, _number);
        };
      } else {
        return declOfNumSubFunction.apply(null, arguments);
      }
    };
  }(),
  loadProducts: function loadProducts(inputPage) {
    var me = this;
    me.prodEl.addClass('loading');
    me.loadButton.parent().hide();
    $.get('katalogpage', me.filtersData).done(function (result) {
      me.totalCount = parseInt(result.Count);
      me.tabsCounter.text('(' + result.Count + ')');

      if (result.Products.length > 0) {
        me.resultButton.text('Найдено ' + result.Count + ' ' + me.declOfNum(['товар', 'товара', 'товаров'], result.Count));

        if (me.page === 0 || inputPage) {
          me.productsHtml.html(me.tmpl.render(result));
        } else {
          $(me.tmpl.render(result)).appendTo(me.productsHtml);
        }

        if (result.Count > me.count && result.Count - me.page * me.count > 0) {
          me.loadButton.parent().show();
        }
      } else {
        me.resultButton.text('Найдено 0 товаров');
        me.productsHtml.html(me.message.render());
      } //rewrite title and header


      var checkedBrandsMobile = $('.m-filters__check > .m-check__input:checked');
      var checkedBrands = $('.filters__check > .check__input:checked');
      var brandName = null;

      if (checkedBrands.length === 1) {
        brandName = checkedBrands.first().attr('data-title');
      }

      if (checkedBrandsMobile.length === 1) {
        brandName = checkedBrandsMobile.first().attr('data-title');
      }

      var newHeader = brandName != null ? me.categoryName + " " + brandName + " – Каталог" : me.categoryName + ' – Каталог';
      var newTitle = brandName != null ? me.categoryName + " " + brandName + ": купить, цены на " + me.categoryName.toLowerCase() + " " + brandName + " | Product-test.ru" : me.metaTitleCategory;
      document.title = newTitle;
      me.category.text(newHeader);
      me.updatePagination(me.page, result.Count);
    }).fail(function () {
      alert("Что-то сломалось, пожалуйста, сообщите нам об этом по адресу info@product-test.ru");
    }).always(function () {
      me.prodEl.removeClass('loading');
    });
  },
  renderBlueStars: function renderBlueStars(value) {
    var rating = '';

    for (var star = 1; star <= 5; star++) {
      var x = star <= value ? 1 : star - value < 1 ? (star - 1 - value) * -1 : 0;
      var y = (Math.asin(2 * x - 1) / Math.PI + 0.5) * 100;
      rating += '<div class="ico ico-star-red-empty-r stars__out"><div class="ico ico-star-red-full-r stars__in" style="width: ' + y + '%;"></div></div>';
    }

    return rating;
  }
};