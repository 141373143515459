"use strict";

ymWidgetLoader = {
  ymWidgetSrc: 'https://aflt.market.yandex.ru/widget/script/loader',
  ymw: $('#ymarket-widget-wrapper'),
  ptw: $('#shops-block-wrapper'),
  sort: $('#sort-block-wrapper'),
  msort: $('#m-sort-block-wrapper'),
  mcitySelect: $('#m-city-select-wrap'),
  overviewYmWidgets: $('.overview-ymw'),
  overviewPtWidgets: $('.overview-ptw'),
  ymWidgetScript: $('#ym-widget-script'),
  offersInitFunc: function offersInitFunc() {},
  experimentLoadPtWidget: false,
  timeout: 4000,
  init: function init(offersInitFunc, experimentLoadPtWidget, timeout) {
    this.timeout = timeout == null ? this.timeout : timeout;
    this.offersInitFunc = offersInitFunc == null ? this.offersInitFunc : offersInitFunc;
    this.experimentLoadPtWidget = experimentLoadPtWidget;
    var me = this;
    setTimeout(function () {
      if (!me.ymWidgetIsload()) {
        me.loadYmWidget();
        me.loadOffers();
      }
    }, me.timeout);
  },
  ymWidgetIsload: function ymWidgetIsload() {
    var overviewWidgetIsLoad = false;

    if (this.overviewYmWidgets && this.overviewYmWidgets.length > 0) {
      //проверяем загрузку только 1 виджета, так как в хроме 2 еще не отрисован и неимеет высоты
      overviewWidgetIsLoad = $(this.overviewYmWidgets[0]).find("iframe").height() > 1;
    }

    return (this.ymw.find('iframe').height() > 1 || overviewWidgetIsLoad) && this.experimentLoadPtWidget;
  },
  loadYmWidget: function loadYmWidget() {
    var script = document.createElement('script');
    script.src = this.ymWidgetSrc;
    document.head.appendChild(script);
    this.ymWidgetScript.remove();

    if (this.overviewYmWidgets && this.overviewYmWidgets.length > 0) {
      this.overviewYmWidgets.each(function () {
        $(this).find("iframe").remove();
      });
    }

    this.ymw.find('iframe').remove();
  },
  loadOffers: function loadOffers() {
    var me = this;
    setTimeout(function () {
      if (!me.ymWidgetIsload()) {
        me.showPtWidget();
        me.hideYmWidget();
        me.offersInitFunc();
      }
    }, me.timeout);
  },
  hideYmWidget: function hideYmWidget() {
    var me = this;

    if (me.overviewYmWidgets && me.overviewYmWidgets.length > 0) {
      me.overviewYmWidgets.each(function () {
        $(this).hide();
      });
    }

    me.ymw.hide();
  },
  showPtWidget: function showPtWidget() {
    var me = this;
    me.ptw.removeClass('hidden');
    if (me.sort != null) me.sort.removeClass('hidden');
    if (me.overviewPtWidgets && me.overviewPtWidgets.length > 0) me.overviewPtWidgets.each(function () {
      $(this).removeClass("hidden");
    });
    if (me.sort != null) me.sort.removeClass('hidden');
    if (me.mcitySelect != null) me.mcitySelect.removeClass('hidden');
  }
};