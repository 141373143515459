"use strict";

var comments = {
  init: function init() {
    $('.reply-btn').on('click', function () {
      var block = $(this).data('id');
      $('span#commentInfo', block).remove();
      $(block).toggle('slow');
      return false;
    });
  }
};
var characteristics = {
  init: function init() {
    $('.rating__check input[type=checkbox]').change(function () {
      var count = $(this).parents('#raiting-body').find('input:checked').length;
      var text = ' товаров';

      if (count % 10 == 1) {
        text = ' товар';
      } else if (count % 10 >= 2 && count % 10 <= 4 && count != 12 && count != 13 && count != 14) {
        text = ' товара';
      }

      $(this).parents('.rating__container').find('.compare-count').text(count + text);
      var compare = [];
      $.each($("#raiting-body").find('input:checked'), function () {
        compare.push('product=' + $(this).val());
      });
      var comparer = $('.rating__compare');
      var link = '/catalog/compare?' + compare.join('&');
      if (compare.length >= 2) comparer.attr('href', link);else comparer.attr('href', '#');
      var redTextClass = 'red-text';

      if (count > 1) {
        $(this).parents('.rating__container').find('.rating__compare').addClass(redTextClass);
      } else {
        $(this).parents('.rating__container').find('.rating__compare').removeClass(redTextClass);
      }
    });
  }
};

function loadScriptsDynamically() {
  // Tooltip
  tooltip.init();
  tooltip2.init(); // Compare

  characteristics.init();
}

$(function () {
  comments.init();
  characteristics.init(); // Slider ( Rating block )

  $('.rating__gray').each(function () {
    var parent = $(this),
        scale = $('<div class="rating__slider-labels" />'),
        slider = parent.find('.slider'),
        sliderW,
        step = slider.data('step'),
        rangeMin = slider.data('min'),
        rangeMax = slider.data('max'),
        fields = parent.find('.rating__range'),
        fieldMin = fields.find('.rating__range-field:first input'),
        fieldMax = fields.find('.rating__range-field:last input'),
        delay = 300,
        config;

    if (rangeMax > rangeMin) {
      fieldMin.val(rangeMin);
      fieldMax.val(rangeMax);
      config = {
        range: [rangeMin, rangeMax],
        start: [rangeMin, rangeMax],
        handles: 2,
        step: step,
        slide: function slide() {
          var values = $(this).val();
          fieldMin.val(values[0]);
          fieldMax.val(values[1]);
          setUserValues(values[0], values[1], true);
        }
      };
      setTimeout(function () {
        scale.append('<div class="rating__slider-start">' + fieldMin.val() + ' р.</div>');
        scale.append('<div class="rating__slider-finish">' + fieldMax.val() + ' р.</div>');
        slider.after(scale).noUiSlider(config);
      }, delay);
    }

    function setUserValues(min, max, setFilterByPrice) {
      $('#noUiSlide-start').data("userValue", min);
      $('#noUiSlide-end').data("userValue", max);

      if (setFilterByPrice) {
        $('input[name=isFilterByPrice]').val(true);
      }
    }

    ; // Обновление ползунков слайдера

    function updateSlider() {
      if (rangeMax > rangeMin) {
        slider.val([rangeMin, rangeMax]); // Обновляем hidden input-ы слайдера из-за бага, например,
        // при установки значения(slider.val(...)) = 1000 устанавливается 9900.

        $('.rating__slider .slider input').first().val(rangeMin);
        $('.rating__slider .slider input').last().val(rangeMax);
        setUserValues(rangeMin, rangeMax, true);
      }
    }

    ;
    fieldMax.on('keyup paste', function () {
      rangeMax = parseFloat($(this).val()) || 0;
      updateSlider();
    });
    fieldMin.on('keyup paste', function () {
      rangeMin = parseFloat($(this).val()) || 0;
      updateSlider();
    });
  });
});