"use strict";

$(document).ready(function () {
  $('body').click(function (event) {
    if ($(event.target).closest('.detail-filter-dropdown2').length > 0) return true;
    $('.detail-filter, .detail-filter-label2').removeClass('expand');
  });
  $('.detail-filter, .detail-filter-label2, .detail-duplex-filter-item').click(function (event) {
    if ($(event.target).closest('.detail-filter-dropdown2, .detail-duplex-filter-dropdown').length > 0) return true;
    $(this).toggleClass('expand').siblings('.expand').removeClass('expand');
    return false;
  });
});